import CryptoJS from 'crypto-js'
import * as dayJs from 'dayjs'
// import MD5 from 'md5'

const cFn = CryptoJS.MD5

const getSkey = () => {
  return 's'
}

const getKKey = () => {
  return 'k'
}

const getTKey = () =>{
  return 't'
}

const getKey = () => {
  return getSkey() + getKKey() + (1 + 1)
}

let date_t
const getValue1 = () => {
  const date = dayJs().second(0).valueOf()
  date_t = String(date).slice(0, -3)
  return date_t
}
const getValue2 = (phone = '', date = '') => {
  const host = window.location.host
  return cFn(cFn(host + phone).toString().slice(10, 30) + date).toString()
}

const getKey2 = () => {
  return getTKey() + getTKey()
}

export const getHostAndPhoneHeaders = (phone) => {
  const date = getValue1()
  const data = {
    [getKey()]: getValue2(phone, date),
    [getKey2()]: date
  }
  return data
}