import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex);
export default new Vuex.Store({
  state: {
    token:localStorage.getItem('token')?localStorage.getItem('token'):null,
    userInfos:localStorage.getItem('userInfos')?JSON.parse(localStorage.getItem('userInfos')):null,
    possessorConfig:localStorage.getItem('possessorConfig')?JSON.parse(localStorage.getItem('possessorConfig')):null,
  },
  getters: {
  },
  mutations: {
    USER_UPDATE(state,datas){
      state.userInfos=datas
      if(datas!=null){
        localStorage.setItem('userInfos',JSON.stringify(datas))
      }
    },
    TOKEN_UPDATE(state,datas){
      state.token=datas
      localStorage.setItem('token',datas)
    },
    USER_POSSESS(state,datas){
      state.possessorConfig=datas
      if(datas!=null){
        localStorage.setItem('possessorConfig',JSON.stringify(datas))
      }
    }
  },
  actions: {},
  modules: {
  }
})
