import { possessorConfig } from './utils'

export const initHmJs = async () => {
  const res = await possessorConfig()
  if (res) {
    const pvuvNum = res.pvuvNum
    if (pvuvNum) {
      var hm = document.createElement("script")
      hm.src = `https://hm.baidu.com/hm.js?${pvuvNum}`
      var s = document.getElementsByTagName("script")[0]
      s.parentNode.insertBefore(hm, s)
    }
  }
}
