import request from './request'
const base = {
	// url: process.env.NODE_ENV == 'development' ? 'https://ntf2022-pre.maytek.cn' : window.location.origin,//http://homehouduanapi.natapp1.cc
	url: process.env.NODE_ENV == 'development' ? 'https://digital.d-universe.net' : window.location.origin,
};

// console.log("环境配置",process.env.NODE_ENV)
const api = {
	//获取短信验证码
	getCode(params) {
		let datas={
			params:params,
			key:true
		}
		return request.post(base.url + "/mall/client/sendMsgCode", datas);
	},
	// 登录
	login(params) {
		let datas={
			params:params,
			key:true
		}
		return request.post(base.url + "/mall/client/v2/mallLogin", datas);
	},
	//个人中心首页
	mineUser(params) {
		return request.get(base.url + "/mall/client/myInfo?userCode=" + params.userCode);
	},
	//获取是否实名认证
	getIsCertification(params) {
		return request.get(base.url + "/mall/client/isCertification?userCode=" + params.userCode);
	},
	//实名认证
	getCertification(params) {
		return request.get(base.url + "/mall/client/certification?userCode=" + params.userCode + '&userPhone=' +
			params.userPhone + '&idCard=' + params.idCard + '&name=' + params.name);
	},
	//获取上传策略
	getUploadPolicy() {
		return request.get(base.url + "/mall/client/getOssUploadPolicy");
	},
	//编辑个人信息
	getEdit(params) {
		return request.put(base.url + "/mall/client/editMetaUser", params);
	},
	//获取首页广告栏
	getIndexBanner() {
		return request.get(base.url + "/mall/client/getCustomPicList");
	},
	//获取公告消息是否展示
	getIsNotice() {
		return request.get(base.url + "/mall/client/notice/isNotice");
	},
	//获取公告消息
	getNotice() {
		return request.get(base.url + "/mall/client/notice/getNotice?pageSize=999&pageNum=1");
	},
	//获取发售日历
	getIpSaleTime(yearsMonth = "") {
		return request.get(base.url + "/mall/client/ipSaleTime?yearsMonth=" + yearsMonth);
	},
	// 获取发售日历藏品
	getIpSaleDataList(saleTime = "") {
		return request.get(base.url + "/mall/client/ipSaleDataList?saleTime=" + saleTime);
	},
	//获取首页新品推荐
	getIndexCp(params) {
		return request.get(base.url + "/mall/client/productList?pageNum=" + params.pageNum + '&pageSize=' + params
			.pageSize + '&orderByColumn=' + params.orderByColumn + '&isAsc=' + params.isAsc);
	},
	//获取首页主题展区
	getTheme(params) {
		return request.get(base.url + "/mall/client/composeList?pageNum=" + params.pageNum + '&pageSize=' + params
			.pageSize);
	},
	//获取主题详情信息
	getThemeDetail(params) {
		return request.get(base.url + '/mall/client/composeInfo?composeCode=' + params.composeCode)
	},
	//获取藏品详情
	getProductInfo(params) {
		return request.get(base.url + "/mall/client/productInfo?artworkCode=" + params.artworkCode);
	},
	//查询艺术家信息
	getAuthorInfo(params) {
		return request.get(base.url + "/mall/client/getAuthorInfo?authorId=" + params.authorId);
	},
	//获取首页创作家
	getAuthor(params) {
		return request.get(base.url + "/mall/client/getIpAuthorList?pageNum=" + params.pageNum + '&pageSize=' +
			params.pageSize);
	},
	//获取个人中心
	getMetaUserShareBroweList(params) {
		return request.get(base.url + "/mall/client/getMetaUserShareBroweList?pageNum=" + params.pageNum +
			'&pageSize=' + params.pageSize + '&orderByColumn=' + params.orderByColumn + '&isAsc=' + params
			.isAsc + '&userCode=' + params.userCode);
	},
	//获取积分列表
	getMyScoredetail(params) {
		return request.get(base.url + "/mall/client/myScoredetail?pageNum=" + params.pageNum + '&pageSize=' + params
			.pageSize + '&userCode=' + params.userCode);
	},
	//获取更多积分的图 赚取积分
	getDivPagByPageCode(params) {
		return request.get(base.url + "/mall/client/getDivPagByPageCode?pageCode=" + params.pageCode);
	},
	//关于我们
	getMetaPossessor() {
		return request.get(base.url + "/mall/client/getMetaPossessor");
	},
	//隐私权限
	getPossessorConfig() {
		return request.get(base.url + "/mall/client/getPossessorConfig");
	},
	//获取藏品列表
	getCollectionList(params) {
		return request.get(base.url + '/mall/client/queryIPArtworkListByUserCode?pageSize=' + params.pageSize +
			'&pageNum=' + params.pageNum + '&userCode=' + params.userCode);
	},
	getCollectionDetail(params) {
		return request.get(base.url + '/mall/client/orderDetail?orderNum=' + params.orderNum + (params.orderType ? '&orderType=' + params.orderType : ''));
	},
	// 下单
	//获取支付通道
	getPayChannel() {
		return request.get(base.url + "/mall/client/getPayChannel");
	},
	// 创建订单
	getAddOrderV2(params) {
		let datas={
			params:params,
			key:true
		}
		// return request.post(base.url + "/mall/client/v2/addOrder", params);
		return request.post(base.url + "/mall/client/v3/addOrder", datas);
	},
	// 创建订单  使用券
	getAddOrder(params) {
		return request.post(base.url + "/mall/client/addOrder", params);
	},
	//查询订单信息
	getOrderInfoByUuid(params) {
		return request.get(base.url + "/mall/client/getOrderInfoByUuid?uuid=" + params.uuid);
	},
	//获取兑换券列表
	getArtWorkCanUsePizes(params) {
		return request.get(base.url + "/mall/client/getArtWorkCanUsePizes?artworkCode=" + params.artworkCode +
			'&userCode=' + params.userCode);
	},
	//获取支付form 表单
	getCreateAlipay(params) {
		return request.post(base.url + "/mall/client/createAlipay", params);
	},
	//杉德支付
	getCreateSandpay(params) {
		return request.get(base.url + "/mall/client/createSandpay?subType=" + params.subType + '&orderNum=' + params
			.orderNum + '&path=' + params.path);
	},
	// 微信支付
	getCreateWeixinOrder(params) {
		return request.post(base.url + "/mall/client/createWeixinOrder", params);
	},
	//获取订单列表
	getOrderList(params) {
		return request.get(base.url + '/mall/client/myOrder?pageNum=' + params.pageNum + '&pageSize=' + params
			.pageSize + '&buyerUsercode=' + params.buyerUsercode + (params.orderStatus ? '&orderStatus=' +
				params.orderStatus : ''))
	},
	//查询用户券列表
	getMetaUserPrizesList(params) {
		return request.get(base.url + '/mall/client/getMetaUserPrizesList?userCode=' + params.userCode +
			'&pageNum=' + params.pageNum + '&pageSize=' + params.pageSize +
			(params.orderByColumn ? '&orderByColumn=' + params.orderByColumn : '') +
			(params.type ? '&type=' + params.type : '') + 
			(params.isAsc ? '&isAsc=' + params.isAsc : ''));
	},
	//获取订单详情
	getOrderDetail(params) {
		return request.get(base.url + "/mall/client/orderDetail?orderNum=" + params.orderNum+
		(params.orderType ? '&orderType=' + params.orderType : ''));
	},
	//取消支付
	getCancelOrder(params) {
		return request.post(base.url + "/mall/client/cancelOrder", params);
	},
	//获取创作家藏品列表
	getIndexList(params) {
		return request.get(base.url + "/mall/client/productList?pageNum=" + params.pageNum + '&pageSize=' + params
			.pageSize + '&orderByColumn=' + params.orderByColumn + '&isAsc=' + params.isAsc + (params.ipAuthor ?
				'&ipAuthor=' + params.ipAuthor : ''));
	},
	//查询奖励记录
	getReward(params) {
		return request.get(base.url + "/mall/client/myScoredetail?pageNum=" + params.pageNum + '&pageSize=' + params
			.pageSize + '&userCode=' + params.userCode + '&orderByColumn=' + params.orderByColumn + '&isAsc=' +
			params.isAsc + '&operatorType=' + params.operatorType)
	},
	//查询奖励记录
	getMetaUserPullNew() {
		return request.get(base.url + "/mall/client/getMetaUserPullNew")
	},
	//获取图形验证码
	getCaptchaImage() {
		return request.get(base.url + "/mall/client/captchaImage");
	},
	//验证图形验证码
	getCheckChar(params) {
		return request.get(base.url + "/mall/client/checkChar?uuid=" + params.uuid + '&code=' + params.code);
	},
	// 微信授权		
	getAuthurl(params) {
		return request.get(base.url + "/mall/client/getAuthurl?path=" + params.path);
	},
	// weixin JS 使用授权 
	getWeixinJsInfo(params) {
		return request.get(base.url + "/mall/client/getWeixinJsInfo?path=" + params.path);
	},
	// 邀请好友生成图片
	addMetaUserShare(params) {
		return request.get(base.url + "/mall/client/addMetaUserShare?userCode=" + params.userCode +
			'&activityCode=' + params.activityCode);
	},
	// /* wxjssdk 注册 */
	getJsSdk(params) {
		return request.get(base.url + "/mall/client/getWeixinJsInfo?path=" + params.path);
	},
	//验证能否转赠
	artCanGiven(params) {
		return request.get(base.url + "/mall/client/artCanGiven?orderNum=" + params.orderNum)
	},
	//确认转赠：
	checkGiven(params) {
		return request.get(base.url + "/mall/client/checkGiven?orderNum=" + params.orderNum + '&linkphone=' + params
			.linkphone + '&fromCode=' + params.fromCode)
	},
	// 转赠支付/mall/client/addFromOrder
	getAddFromOrder(params) {
		return request.post(base.url + "/mall/client/addFromOrder", params);
	},
	// 优先购藏品列表
	getCouponProductList(params) {
		return request.get(base.url + "/mall/client/couponProductList?pageNum=" + params.pageNum + '&pageSize=' +
			params.pageSize + '&userPrizesId=' + params.userPrizesId);
	},
	// 优先购创建订单
	getAddBeforeOrder(params) {
		return request.post(base.url + "/mall/client/addBeforeOrder", params);
	},
	// 邀请好友生成图片
	addMetaUserShare(params) {
		return request.get(base.url + "/mall/client/addMetaUserShare?userCode=" + params.userCode +
			'&activityCode=' + params.activityCode);
	},
	
	//验证能否转赠
	artCanGiven(params) {
		return request.get(base.url + "/mall/client/artCanGiven?orderNum=" + params.orderNum)
	},
	//确认转赠：
	checkGiven(params) {
		return request.get(base.url + "/mall/client/checkGiven?orderNum=" + params.orderNum + '&linkphone=' + params
			.linkphone + '&fromCode=' + params.fromCode)
	},
	// 转赠支付/mall/client/addFromOrder
	getAddFromOrder(params) {
		return request.post(base.url + "/mall/client/addFromOrder", params);
	},
	// 优先购藏品列表
	getCouponProductList(params) {
		return request.get(base.url + "/mall/client/couponProductList?pageNum=" + params.pageNum + '&pageSize=' +
			params.pageSize + '&userPrizesId=' + params.userPrizesId);
	},
	// 优先购创建订单
	getAddBeforeOrder(params) {
		return request.post(base.url + "/mall/client/addBeforeOrder", params);
	},

	// 查询藏品系列
	getArtworkByIpcode(params) {
		return request.get(base.url + "/mall/client/getArtworkByIpcode?ipCode=" + params.ipCode);
	},
	// 查询藏品系列
	getDiscoveryCollection(params) {
		return request.get(base.url + "/mall/client/DiscoveryCollection?artworkCode=" + params.artworkCode);
	},
	//开启盲盒
	getOpenBoxDetail(params) {
		return request.get(base.url + "/mall/client/orderDetail?orderNum=" + params.orderNum + '&orderType=' +
			params.orderType);
	},
	// 合成活动列表
	getSynList(params) {
		return request.get(base.url + "/mall/client/synList?pageNum=" + params.pageNum + '&pageSize=' +params.pageSize);
	},
	// 合成信息
	getSynInfo(params) {
		return request.get(base.url + "/mall/client/synInfo?heChengCode=" + params.heChengCode);
	},
	// 合成记录
	getSynLogList(params) {
		return request.get(base.url + "/mall/client/synLogList?pageNum=" + params.pageNum + '&pageSize=' +params.pageSize + '&hechengStatus=' + params.hechengStatus||'');
	},
	//获取持有的藏品列表
	getUserArtworkList(params) {
		return request.get(base.url + "/mall/client/userArtworkList?artworkCode=" + params.artworkCode);
	},
	//获取入条件的藏品列表
	getInProductList(params) {
		return request.get(base.url + "/mall/client/inProductList?heChengCode=" + params.heChengCode);
	},
	// 合成创建订单
	getAddHeCheng(params) {
		return request.post(base.url + "/mall/client/addHeCheng", params);
	},
	//mall/client/synLogInfo
	//获取合成订单详情
	getSynLogInfo(params) {
		return request.get(base.url + "/mall/client/synLogInfo?userHeChengCode=" + params.userHeChengCode);
	},
	//获取授权钱包列表
	getWalletPlatformList(params) {
		return request.get(base.url + "/mall/client/getWalletPlatformList");
	},
	// 获取钱包授权地址
	getSLAuthSite(params) {
		return request.get(base.url + "/mall/client/getSLAuthSite");
	},
}
export default api;
