import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Vant from 'vant' 
import 'vant/lib/index.css'
import store from './store/'; //引入store
import { Lazyload } from 'vant';
import dayjs from 'dayjs';
import VueClipboard from 'vue-clipboard2' //复制粘贴
import {formatNum} from './assets/js/utils'
import md5 from 'js-md5'
Vue.prototype.$md5 = md5
// import VConsole from 'vconsole'
// var vConsole = new VConsole();

Vue.prototype.$dayjs = dayjs;
Vue.prototype.$imgUrl = 'https://file-adwx.oss-cn-hangzhou.aliyuncs.com/';
Vue.prototype.$zjfUrl = 'https://file-adwx.oss-cn-hangzhou.aliyuncs.com/newImages/zjf/';
Vue.prototype.$zjfNewurl = 'https://file-adwx.oss-cn-hangzhou.aliyuncs.com/newImg/';

Vue.prototype.$formatNum = formatNum;

Vue.config.productionTip = false
Vue.use(Vant);
Vue.use(Lazyload);
Vue.use(VueClipboard);


router.beforeEach((to,from,next) => {
  if (to.matched.some(record => record.meta.requireAuth)) {  // 判断当前路由是否需要权限
    if (localStorage.getItem("token")) { // 判断当前是否存在 token
      next()
    } else {
      next({
        path: '/',
        query: {
          redirect: to.fullPath // 将跳转的路由path作为参数，登录成功后跳转到改路由
        }
      })
    }
  } else {
    next()
  }
})
router.afterEach(() => {
  window.scrollTo(0,0);
});

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
