// 基本配置
export default {
  // 本地
  "http://192.168.50.37:8080": {
      appid: "wx6ed191739514fe58", //微信公众号appid
      redirect_uri:window.location.origin, //公众号授权回调地址
      env: "develop", //当前环境
      https: "/api", //请求地址
      minHash:'https://testnet.confluxscan.io/address/'
  },
  // 测试
  "https://ntf2022-pre.maytek.cn": {
      appid: "wx17c70594bdc8badf", //微信公众号appid
      redirect_uri: window.location.origin+'/#/', //公众号授权回调地址
      env: "test", //当前环境
      https: "", //请求地址
      minHash:'https://testnet.confluxscan.io/address/'
  },
  // 正式
  "https://digital.d-universe.net": {
      appid: "wxce9845a11fad489f", //微信公众号appid
      redirect_uri: window.location.origin+'/#/', //公众号授权回调地址 
      env: "release", //当前环境
      https: "", //请求地址
      minHash:'https://confluxscan.io/address/'
  },
}