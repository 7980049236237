import api from '../http/api'
import router from '@/router'
import wx from 'weixin-sdk-js'
import {
	Toast
} from "vant"
import html2canvas from "html2canvas";
import QRCode from 'qrcode'
export async function chooseWXPay(orderNum) {
	const res = await api.getCreateWeixinOrder({
		orderNum
	})
	// console.log(res)
	if (res.code == 200) {
		let data = res.data
		// console.log(data)
		wx.chooseWXPay({
			appId: data.appId,
			timestamp: data.timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
			nonceStr: data.nonceStr, // 支付签名随机串，不长于 32 位
			package: data.packageValue, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
			signType: data.signType, // 微信支付V3的传入RSA,微信支付V2的传入格式与V2统一下单的签名格式保持一致
			paySign: data.paySign, // 支付签名
			success: function(res) {
				if (res.errMsg === 'chooseWXPay:ok') {
					router.replace({
						name: 'orderInfos',
						query: {
							orderNum: orderNum
						}
					})
				}
			},
			// 支付取消回调函数
			cancel: function () {
				Toast('取消支付')
				return false;
			},
			// 支付失败回调函数
			fail: function () {
				Toast('支付失败');
				return false;
			}
		})
	}else{
		Toast(res.msg);
		return false;
	}
}


// 判断是否是在微信环境
export function isWxEnv() {
	const ua = navigator.userAgent.toLowerCase();
	if (ua.match(/MicroMessenger/i) == "micromessenger") {
		//ios的ua中无miniProgram，但都有MicroMessenger（表示是微信浏览器
		return true
	} else {
		return false
	}
}

export let canUseWxApi = false
export const initConfig = async ({timestamp, appId, nonceStr, signature}, {
	title,
	desc,
	link,
	imgUrl
  }) => {
	wx.config({
		debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
		appId, // 必填，公众号的唯一标识
		timestamp, // 必填，生成签名的时间戳
		nonceStr, // 必填，生成签名的随机串
		signature,// 必填，签名
		jsApiList: [
			'updateAppMessageShareData',
			'updateTimelineShareData',
			'onMenuShareAppMessage',
			'onMenuShareTimeline'
		] // 必填，需要使用的JS接口列表
	})
	wx.ready(() => {
		// console.log('wx.ready')
		canUseWxApi = true
		updateAppMessageShareData({title, desc, link, imgUrl})
		updateTimelineShareData({title, desc, link, imgUrl})
	})
	wx.error(() => {
		// console.log('wx.error')
		// canUseWxApi.value = false
	})
	wx.checkJsApi({
		jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData'], // 需要检测的JS接口列表，所有JS接口列表见附录2,
		success: function(res) {
			// console.log(res, 'wx.checkJsApi')
		// 以键值对的形式返回，可用的api值true，不可用为false
		}
	});
  }

  // 自定义“分享给朋友”及“分享到QQ”按钮的分享内容（1.4.0）
export const updateAppMessageShareData = ({title, desc, link, imgUrl, success}) => {
	wx.updateAppMessageShareData({
		title, // 分享标题
		desc, // 分享描述
		link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
		imgUrl, // 分享图标
		success: function () {
			// console.log('updateAppMessageShareData:success')
			success && success()
			// 设置成功
		}
	})
  }
  
  // 自定义“分享到朋友圈”及“分享到QQ空间”按钮的分享内容（1.4.0）
  
  export const updateTimelineShareData = ({title, desc, link, imgUrl, success}) => {
	wx.updateTimelineShareData({
		title, // 分享标题
		link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
		imgUrl, // 分享图标
		desc,
		success: function () {
			// console.log('updateTimelineShareData:success')
			success && success()
		// 设置成功
		}
	})
  }


let possessorConfigDataInfo

let getPossessorConfigFnLoading = false
const getPossessorConfigFn = async () => {
	if (getPossessorConfigFnLoading) {
		return new Promise((resolve, reject) => {
			setTimeout(async () => {
				resolve(await getPossessorConfigFn())
			}, 500)
		})
	} else {
		if (possessorConfigDataInfo) {
			return {
				data: possessorConfigDataInfo,
				code: 200
			}
		} else {
			getPossessorConfigFnLoading = true
			let res = await api.getPossessorConfig()
			getPossessorConfigFnLoading = false
			return res
		}
	}
}

// 获取系统公共配置信息 refresh 是否获取最新数据
export async function possessorConfig(refresh = false) {
	if (refresh) {
		const {
			code,
			data,
			msg
		} = await api.getPossessorConfig({}, true)
		if (code == 200) {
			possessorConfigDataInfo = data
			return data
		}
	} else {
		if (possessorConfigDataInfo) return possessorConfigDataInfo
		const {
			code,
			data,
			msg
		} = await getPossessorConfigFn()
		if (code == 200) {
			possessorConfigDataInfo = data
			return data
		}
	}
	// Toast.fail(msg)
	return false
}
export function getBase64Image(url) {
	return new Promise((res, rej) => {
		var image = new Image()
		image.crossOrigin = 'Anonymous'
		image.src = url
		image.onload = function() {
			var canvas = document.createElement("canvas")
			canvas.width = image.width
			canvas.height = image.height
			var ctx = canvas.getContext("2d")
			ctx.drawImage(image, 0, 0, image.width, image.height)
			var ext = image.src.substring(image.src.lastIndexOf(".") + 1).toLowerCase()
			var dataURL = canvas.toDataURL("image/" + ext)
			res(dataURL)
		}
		image.onerror = (e) => {
			res('')
			// rej(e)
		}
	})
}
/**
 * 
 * @param {canvasDom} 生成成图片的 DOM 元素 
 * @param {scaleNum} 放大倍数 
 */
export async function setCanvasToImage(canvasDom, scaleNum) {
	// 生成图片的 DOM 元素
	if (!canvasDom) return;
	// 设置放大倍数
	const scale = scaleNum || window.devicePixelRatio;
	// 传入节点原始宽高
	const _width = canvasDom.offsetWidth;
	const _height = canvasDom.offsetHeight;
	// html2canvas配置项
	const ops = {
		dpi: window.devicePixelRatio || 100, //加了一个这个设置
		scale,
		useCORS: true,
		allowTaint: true,
		logging: false,
		backgroundColor: 'transparent',
		width: _width,
		height: _height,
		imageTimeout: 60000,
		scrollY: 0,
		scrollX: 0,
	};
	let url = ''
	// console.log("修改安全")
	const canvas = await html2canvas(canvasDom, ops)
	// 返回图片的二进制数据
	Toast.clear();
	// console.log(canvas,'canvas')
	try {
		url = canvas.toDataURL("image/png");
		// 去除字符串中的空格和换行
	} catch (error) {
		Toast("图片生成失败！");
	}
	if (!canvas) {
		Toast('生成图片失败')
	}
	return url
}
// 生成二维码
export async function getQRCode(actUrl = '') {
	// QRCode.toDataURL(window.origin + '/#/')
	try {
		let url = await QRCode.toDataURL(actUrl, {
			errorCorrectionLevel: 'L'
		})

		return url
	} catch (err) {
		// console.log(err, "=======生成二维码失败");
		Toast.fail('生成二维码失败')
	}
}
// 生成透明底二维码
export async function getQRCodeTrans(actUrl = '') {
	// QRCode.toDataURL(window.origin + '/#/')
	try {
		let url = await QRCode.toDataURL(actUrl, {
			errorCorrectionLevel: 'L',
			color: {
				dark: '#fff', // 二维码背景颜色
				light: 'pink' // 二维码前景颜色
			},
		})

		return url
	} catch (err) {
		// console.log(err, "=======生成二维码失败");
		Toast.fail('生成二维码失败')
	}
}
// 转换号码格式 151****5624
export function formatNum(num) {
	let numf = num.replace(/^(\d{3})\d{4}(\d+)/, "$1****$2");
	return numf
}
