import axios from "axios";
import { Toast } from 'vant';
import Vue from 'vue'
import router from '@/router'
import qs from "qs"; // 根据需求是否导入qs模块
import { getHostAndPhoneHeaders } from './loginUrl'
//创建axios的一个实例
var instance = axios.create({
  timeout: 10000000, //设置超时
});
// console.log(getHostAndPhoneHeaders())
// 设置post请求头
// instance.defaults.headers.common['Cache-Control'] = 'no-cache'
instance.defaults.headers.post["Content-Type"] ="application/x-www-form-urlencoded;charset=UTF-8";
instance.defaults.headers.put["Content-Type"] ="application/x-www-form-urlencoded;charset=UTF-8";
instance.defaults.headers.get["Content-Type"] = "application/json";
instance.defaults.headers.common['Cache-Control'] = 'no-cache'
// instance.defaults.headers.Accept = "*/*";

//------------------- 一、请求拦截器 忽略
instance.interceptors.request.use(
  function (config) {
    // console.log(location.origin)
    // console.log(config.data)
		config.headers.possessor = '00000000'
    if(config.method=='post'){
			// config.headers['tt'] =getHostAndPhoneHeaders().tt
			// config.headers['sk2'] =getHostAndPhoneHeaders().sk2
      let configs=JSON.parse(JSON.stringify(qs.parse(config.data)))
      if(configs.tt && configs.sk2){
        config.headers['tt'] =configs.tt
        config.headers['sk2'] =configs.sk2
      }
			if(configs.tt && configs.seccfetchreq){
			  config.headers['tt'] =configs.tt
			  config.headers['secc-fetch-req'] =configs.seccfetchreq
			}
      


      if((configs.tt && configs.sk2) || configs.seccfetchreq){
        Vue.delete(configs,'tt')
        Vue.delete(configs,'sk2')
        Vue.delete(configs,'seccfetchreq')
        if(configs.linkphone){
          config.data=qs.stringify(configs)
        }else{
          config.data=configs
        }
        
      }else{
        // v2升级v3接口  发送验证码 登录 创建订单接口
        if(config.data.key){
          config.headers["Content-Type"] = "application/json";
          if(config.data.params.tt && config.data.params.sk2){
            config.headers['tt'] =config.data.params.tt
            config.headers['sk2'] =config.data.params.sk2
            Vue.delete(config.data.params,'tt')
            Vue.delete(config.data.params,'sk2')
          }


          if(config.data.params.tt && config.data.params.seccfetchreq){
            config.headers['tt'] =config.data.params.tt
            config.headers['secc-fetch-req'] =config.data.params.seccfetchreq
            Vue.delete(config.data.params,'tt')
            Vue.delete(config.data.params,'seccfetchreq')
          }

          let headKey = 'secc-fetch-hash-key'
          let canUseWebAssembly = !!window.WebAssembly && !!window.fetch && !!window.jak0TGv
          if (canUseWebAssembly) {
            config.headers[headKey] = window.jak0TGv(JSON.stringify(config.data.params))
          }
          config.data=config.data.params
        }else{
          config.data=qs.stringify(config.data)
        }
        
      }
    }
    localStorage.getItem("token") && (config.headers['token'] = localStorage.getItem("token"));
    localStorage.getItem("linkphone") && (config.headers['linkphone'] = localStorage.getItem("linkphone"));
    return config;
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

//----------------- 二、响应拦截器 忽略
instance.interceptors.response.use(
  function (response) {   
    if (response.data.code == 200 || response.data.code == 500 || response.data.code == 100 || response.data.code == 210 || response.data.code == 101) {
      return response.data
    }else if(response.data.code == 401){
      Toast(response.data.msg);
      localStorage.removeItem('userInfos')
      localStorage.removeItem('linkphone')
      localStorage.removeItem('token')
      localStorage.removeItem('firstUrl')
      router.replace('/login')
    }else{
      Toast(response.data.msg);
    }
  },
  function (error) {
    // 对响应错误做点什么
    // console.log("拦截器报错");
    Toast.clear()
    return Promise.reject(error);
  }
);

export default instance;
