<template>
  <div>
    <div class="headers" :class="addBgColorShow?'headersBg':''">
      <img class="backIcon" src="https://file-adwx.oss-cn-hangzhou.aliyuncs.com/images/left.png" alt="" @click="goBack">
      <div class="title">
        <div v-if="text || text==''" class="textTitle">{{text}}</div>
        <img v-else :src="'https://file-adwx.oss-cn-hangzhou.aliyuncs.com/images/title/'+title+'.png'" alt="" :style="{'width':width}">
      </div>
      <img :src="type?'https://file-adwx.oss-cn-hangzhou.aliyuncs.com/images/home.png':'https://file-adwx.oss-cn-hangzhou.aliyuncs.com/images/user.png'" alt=""  class="userIcon" v-if="!show && hideIcon" @click="UserCenter">
      <img class="backIcon" src="https://file-adwx.oss-cn-hangzhou.aliyuncs.com/images/left.png" alt="" v-if="!hideIcon" style="opacity: 0;">
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Header',
  props: {
    title: String,
    width:String,
    hideIcon:Boolean,
    text:String,
    backPath:String,
    pageBack:Boolean,
    type:String,
    path:String
  },
  data() {
    return {
      show: false,
      addBgColorShow:false
    };
  },
  mounted() {
    // window.addEventListener('scroll', this.handleScroll);
    // window.addEventListener("popstate", function() { 
    //     this.goBack()
    // }, false); 
  },
  methods:{
    //监听页面滚动
    handleScroll(){
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      if (scrollTop > 5) {
        this.addBgColorShow = true
      } else {
        this.addBgColorShow = false
      }
    },
    goMine(){
      if(localStorage.getItem('token')){
        this.$router.push('/mine')
      }else{
        this.$router.push('/login')
      }
    },
    UserCenter(){
      if(this.$route.path=='/orderPayment'){
        this.$router.push('/mine');
      }else{
        this.$router.replace('/mine');
        // this.$router.go(-1)   //重点
      }
      // if(this.path){
      //   this.$router.push('/mine')
      // }else{
      //   this.$router.back()
      // }
    },
    goBack(){
      // if(this.backPath){//返回指定路径
      //   this.$router.push(this.backPath)
      // }else{
      //   this.$router.back()
      // }
      if (window.sessionStorage.firstUrl === window.location.href) {
        this.$router.replace('/')
        // obj.closeWin() // 这里我是和原生交互的，closeWin可以直接关闭H5窗口
      } else {
        if(this.backPath){//返回指定路径
          if(this.backPath=='/mine'){
            this.$router.replace(this.backPath);
            // this.$router.go(-1) 
          }else{
            this.$router.replace(this.backPath);
          }
          return;
        }
        // if(this.backPath){//返回指定路径
        //   this.$router.replace(this.backPath)
        //   return;
        // }
        if(this.pageBack){
          this.$emit('back')
          return;
        }
        this.$router.back()
        // window.history.go(-1)
      }


      
    }
  }
}
</script>


<style lang="less" scoped>
@import url('../assets/css/common.less');
/deep/ .van-overlay{
  background:none;
}
/deep/ .van-popup--left{
  width: 60%;
  height: 100%;
  background:none;
}
.headersBg{
  background: #00157A;
}
.textTitle{
  width: 80%;
  font-size:0.28rem;
  font-weight: bold;
  text-align: center;
  white-space: nowrap; /*文本强制一行显示*/
  overflow: hidden; /*文本溢出隐藏*/
  text-overflow: ellipsis; /*溢出的文本显示省略号*/
  color:#fff !important;
}
.headers{
    width:100%;
    height: 0.66rem;
    padding:0 0.37rem 0 0.24rem;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background:#001489;
    .titles>span:nth-of-type(1){
      margin-right:0.2rem;
    }
    position: fixed;
    top:0;
    z-index:999;
}
.title{
  flex:1;
  height: inherit;
  font-size:0.32rem;
  color:#fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.titlesIcon{
  display: flex;
  align-items: center;
}
.meauIcon{
  width:0.32rem;
  height:0.26rem;
}
.logoIcon{
  width:3rem;
  margin-left:0.14rem;
}
.userIcon{
  height: 0.28rem;
}
.backIcon{
  height: 0.14rem;
}
</style>
