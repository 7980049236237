<template>
	<div class="contents">
		<img class='bgPositions' :src="imgUrl+'loginBg.png'" alt="" />
		<loginAnimate :arr="artList"></loginAnimate>
		<Header text="登录注册" width="1.38rem" :hideIcon="false"></Header>
		<div class="middel">
			<div class="logoBg">	
				<div class="logoText">欢迎来到</div>
				<img class="logoBorder" :src="imgUrl+'m/白色.png'" alt="">
			</div>
			<div class="formContent">
				<div class="formText">请登录以继续</div>
				<div class="form">
					<div class="formItem">
						<!-- 手机号 -->
						<div class="formInput">
							<van-field ref="phones" v-model="form.phone" type="number" placeholder="请输入手机号"/>
						</div>
					</div>
					<div class="formItem">
						<!-- 验证码 -->
						<div class="formInput">
							<van-field ref="codes" v-model="form.code" type="number" maxlength="6" @blur="isBlur" placeholder="请输入验证码"/>
							<div class="code" @click="handleGetImg">
								{{codeName}}
							</div>
						</div>
					</div>
					<div class="notic">
						<img :src="imgUrl+'checkBox.png'"  alt="" >
						<div>登录即同意	<span @click="goNext('userLogon')">《⽤户协议》</span>和<span @click="goNext('privacy')">《隐私政策》</span></div>
					</div>
					<div class="formBottom">
						<div class="btns" @click="subimt">
							<img :src="imgUrl+'login_Btn.png'" alt="">
							<span>登录</span>
						</div>
					</div>
				</div>
			</div>
			<!-- <Footer></Footer> -->
		</div>
		
		<van-popup v-model="codeShowModel" closeable :close-on-click-overlay="false" @click-close-icon="closeModel">
			<div class="modelCenter">
				<div class="modelTitle">请先完成验证</div>
			  <div class="modelMiddleWrap">
					<div class="modelMiddle">
						<van-field ref="codesValue" v-model="imgCode.code" placeholder="验证码" />
						<img :src="imgCode.img" alt="" @click="getImgCode">
					</div>
				</div>
				<div class="modelLine"></div>
				<div class="btns" @click="submitImg">
					立即验证
				</div>
			</div>
		</van-popup>
	</div>
</template>

<script>
	import {isWxEnv} from '../assets/js/utils'
	import Cryptojs from 'crypto-js'
	import * as dayJs from 'dayjs'
	import { Toast } from 'vant';
	import Header from '@/components/headerBack.vue'
	import api from '../assets/http/api'
	import loginAnimate from '@/components/loginAnimate.vue'
	var Reg = /^[1][0-9][0-9]{9}$/;
	export default {
    name:'Login',
		components: {Header,loginAnimate},
		data() {
			return {
				codeShowModel:false,
				codeShowModelBtn:true,
				coutDown:300,
				codeName:'获取验证码',
				form:{
					phone:'',
					code:''
				},
				imgCode:{
					uuid:'',
					img:'',
					code:'',
				},
				checkBox:true,//勾选协议
				redirect:'',//重定向
				artList:[],//作品列表
				imgUrl:'https://file-adwx.oss-cn-hangzhou.aliyuncs.com/newnewImg/images/',//图片路径
			}
		},
		watch:{
          $route:{
            handler:function(route){
              const query = route.query
			// console.log(query)
              if(query){
                this.redirect = query.redirect;
				// console.log(this.redirect)
              }

            },
            immediate:true
          },
        },
		computed:{
			possessorConfig(){
				let datas=this.$store.state.possessorConfig
				return datas
			},
		},
		unmounted(){
			this.isClosePage = true
			this.$refs.phones.blur();
			this.$refs.codes.blur();
			this.$refs.codesValue.blur();
		},
		mounted() {
			this.getImgCode();
			this.getArtList();
			// if(isWxEnv()){
			// 	api.getWeixinJsInfo({path:''}).then((res)=>{
			// 		if(res.code==200){
			// 			this.weixiJsonInfos=res.data
			// 		}
			// 		console.log(res)
			// 	})
			// }
		},
		methods: {
			getImgStyle(item,index){
				return {
					width:item.size+'rem',
					height:item.size+'rem',
					left:item.pos+'rem'
				}
			},
			getArtList(){
				let query = {
					pageNum: 1,
					pageSize: 10,
					orderByColumn: 'a.sort',
					isAsc: 'desc',
					ipAuthor: ''
				}
				api.getIndexList(query).then(res=>{
					if(res.code==200){
						let arr = res.rows;
						for(let i=0;i<arr.length;i++){
							let obj ={
								url:arr[i].artworkImages,
								time:i
							}
							this.artList.push(obj);
						}
					}
				})
			},
			//随机获取尺寸
			getRandomSize(){
				let arr= [1.43,2.45,3.25,4.27];
				let index =  Math.floor((Math.random()*arr.length)); 
				return arr[index];
			},
			//获取随机位置
			getRandomPos(){
				let dw = document.body.clientWidth*2;
				let pos= Math.floor(Math.random()*dw);
				return pos/100;
			},
			closeModel(){
				Toast.clear()
			},
			isBlur(){
				this.form.code = this.form.code.substr(0,6)
			},
			goNext(value){
				this.$router.push({
					path:'/othersPage',
					query:{
						type:JSON.stringify(value)
					}
				})
			},
			handleGetImg(){
				if(Reg.test(this.form.phone)){
					if(!this.codeShowModelBtn){
						return;
					}
					this.codeShowModel=true;
				}else{
					Toast('请输入正确的手机号格式')
				}
			},
			//获取图形验证码
			getImgCode(){
				api.getCaptchaImage().then((res)=>{
					if(res.code==200){
						this.imgCode={
							uuid:res.uuid,
							img:'data:image/png;base64,'+res.img
						}
					}
				})
			},
			//验证图形验证码
			submitImg(){
				if(!this.codeShowModelBtn){
					return;
				}
				if(this.imgCode.code=='' || this.imgCode.code==null || this.imgCode.code==undefined){
					Toast('请输入验证码');
					return;
				}
				this.codeShowModelBtn=false;
				Toast.loading({
					duration: 0, 
					message: '提交中...',
					forbidClick: true,
				});
				api.getCheckChar({uuid:this.imgCode.uuid,code:this.imgCode.code}).then((res)=>{
					if(res.code==200){
						this.getCodeFunction();
					}else{
						Toast(res.msg);
						this.codeShowModelBtn=true;
					}
				}).catch(()=>{
					this.codeShowModelBtn=true;
				})

			},
			//获取验证码
			getCodeFunction(){
				localStorage.setItem("linkphone",this.form.phone)
				let config={
					linkphone:this.form.phone,
					msgModel:'login',
					'tt': this.getDate(),
					'sk2': this.getSk2Don()
				}
				api.getCode(config).then((res)=>{
					if(res.code==200){
						Toast('发送成功')
						this.codeShowModelBtn = false;
						this.getTime()
					}else if(res.code==500){
						Toast.clear()
						// this.coutDown=res.data
						// this.getTime()
						this.coutDown='获取验证码'
						Toast(res.msg)
						this.codeShowModelBtn = false;
					}else if(res.code==101){
						this.codeShowModel=false;
						this.codeShowModelBtn = true;
						Toast(res.msg)
					}else{
						Toast(res.msg)
						this.codeShowModelBtn = true;
					}
					this.codeShowModel=false;
				})
				
			},
			//倒计时
			getTime(){
				let timeId=setInterval(()=>{
					this.coutDown--;
					this.codeName=this.coutDown+'秒后获取';
					if(this.coutDown<=0){
						clearInterval(timeId)
						this.coutDown=300
						this.codeName='获取验证码'
						this.codeShowModelBtn=true
					}
				},1000)
			},
			//提交
			subimt(){
				if(!Reg.test(this.form.phone)){
					Toast('请输入正确的手机号格式')
					return;
				}
				if(this.form.code==''){
					Toast('请输入短信验证码')
					return;
				}
				// if(!this.checkBox){
				// 	Toast('请阅读并勾选用户协议')
				// 	return;
				// }
				Toast.loading({
					duration: 0, 
					message: '登录中...',
					forbidClick: true,
				});
				let config={
					linkphone:this.form.phone,
					msgCode:this.form.code,
					type:'mes',
					tt:this.getDate(),
					sk2:this.getSk2Don()
				}
				api.login(config).then((res)=>{
					if(res.code==200){
						localStorage.setItem('linkphone',this.form.phone);
						localStorage.setItem('token',res.data.token);
						localStorage.setItem('userInfos',JSON.stringify(res.data));
						this.$store.commit('USER_UPDATE',res.data)
						this.$store.commit('TOKEN_UPDATE',res.data.token)

						//判断在微信中打开
						if(isWxEnv()){
							let host = window.location.href.split('#')[0]
							let hash = '#/'
							let url=host+hash
							api.getAuthurl({path:url}).then((res)=>{
								if (res.code==200) {
									Toast.clear();
									window.location.href =res.data
								}else{
									Toast.clear();
									this.$router.back()
								}
							})
						}else{
							if(window.sessionStorage.firstUrl === window.location.href){
								this.$router.replace('/')
							}else{
								this.$router.back()
							}
							// if(this.redirect!='' && this.redirect!=undefined){
							// 	if(this.redirect=='/login'){
							// 		this.$router.replace('/')
							// 	}else{
							// 		this.$router.replace({path:this.redirect})
							// 	}
							// }else {
							// 	this.$router.replace('/')
							// }
							Toast('登录成功')
						}
					}else{
						Toast.clear();
						Toast(res.msg)
					}
				})
			},
			//获取当前时间
			getDate(){
				let date = dayJs().second(0).valueOf()
				let date_t = String(date).slice(0, -3)
				return date_t
			},
			//获取sk2
			getSk2Don() {
				let url = process.env.NODE_ENV == 'development' ? 'https://ntf2022-pre.maytek.cn' : window.location.origin
				let host = url.split('//')[1]
				let valuse = this.$md5(this.$md5(host + this.form.phone).substring(10,30) + this.getDate()) ;
				return valuse;
			},
		}
	}
</script>

<style lang="less" scoped>
@import url('../assets/css/common.less');
.contents{
	max-width: 100vw;
	min-height: 100%;
	display: flex;
	flex-direction: column;
	position: relative;
	overflow: hidden;
	.bgPositions{
		width:100%;
		position: absolute;
		height: 100%;
		left: 50%;
		margin-left: -50%;
	}
	.middel{
		flex:1;
		display: flex;
		flex-direction: column;
		position: relative;
		z-index:1;
		background-color: rgba(0, 36,149,.8);
		background-image: url('https://file-adwx.oss-cn-hangzhou.aliyuncs.com/newnewImg/images/loginBg.png');
		background-size: 100% 100%;
		background-repeat: no-repeat;
		.formContent{
			width: 6.7rem;
			flex:1;
			display: flex;
			flex-direction: column;
			margin:1.87rem auto 0;
			.formText{
				font-size: 0.3rem;
				padding-left: 0.24rem;
				color: rgba(255, 255, 255, .5);
			}
		}
		.logoBg{
			width: 100%;
			height: 1rem;
			margin-top: 2.4rem;
			padding-left:0.64rem;
			.logoText{
				font-size: 0.4rem;
				font-weight: bold;
				color: #fff;
			}
			.logoBorder{
				width: 4.86rem;
				height: 0.57rem;
				margin-top: 0.2rem;
				/* width: 100%;
				position: absolute; */
				/* bottom: -0.02rem; */
			}
		}
		.form{
			width: 100%;
			height: 5.27rem;
			display: flex;
			flex-direction: column;
			margin-top:0.16rem;
			padding:0.43rem 0.56rem;
			box-sizing: border-box;
			background:url('https://file-adwx.oss-cn-hangzhou.aliyuncs.com/newnewImg/images/login-box.png');
			background-size: cover;
			.formItem{
				width:100%;
				display: flex;
				flex-direction: column;
				.formInput{
					display: flex;
					flex-direction: row;
					align-items: center;
					justify-content: space-between;
					position: relative;
					.code{
						position: absolute;
						top:50%;
						right:0;
						transform:translateY(-50%);
						width: 2rem;
						font-size:0.3rem;
						color:#071683;
						text-align:right;
					}
				}
			}
			.notic{
				display: flex;
				align-items: center;
        justify-content: center;
				box-sizing: border-box;
        font-size: 0.26rem;
        color:#898DA7;
        margin-top: 0.42rem;
        img{
          width:0.32rem;
          height: 0.32rem;
					margin-right: 0.16rem;
        }
        div{
          color:#898DA7;
          font-size: 0.26rem;
        }
        span{
          color:#071683;
          font-size: 0.26rem;
        }
			}
			.formBottom{
				width:100%;
				height: 0.88rem;
				box-sizing: border-box;
				margin-top:0.56rem;
				.btns{
					width:4.8rem;
					height:100%;
					margin:0 auto;
					position: relative;
					img{
						width:100%;
						height:0.9rem;
					}
					span{
						position: absolute;
						top:50%;
						left:50%;
						transform: translate(-50%,-50%);
						font-size: 0.32rem;
						color:#fff;
						font-weight: bold;
						letter-spacing: 0.04rem;
					}
				}
			}
		}
	}
	.animateArea{
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		position: relative;
		img{
			position: absolute;
			bottom:0;
			animation:moveTop 10s linear infinite
		}
	}
}
@keyframes moveTop {
	0%{
		bottom:0;
	}
	100%{
		top: 0;
	}
}
/deep/ .van-field__control{
  font-size: 0.34rem;
  color: #FA4615;
  font-weight: bold;
}
/deep/ .van-field__control::-webkit-input-placeholder{
  font-size: 0.26rem;
  font-weight: normal;
}
/deep/ .van-field__control::-moz-placeholder{
  font-size: 0.26rem;
  font-weight: normal;
}
/deep/ .van-field__control::-ms-input-placeholder{
  font-size: 0.26rem;
  font-weight: normal;
}
/deep/ .van-field__control::placeholder{
  font-size: 0.26rem;
  font-weight: normal;
}
/deep/ .van-popup{
	width:6.1rem;
	border-radius:0.16rem;
}
/deep/ .van-checkbox{ 
	justify-content: center;
}
.modelCenter{
	width:100%;
	height:3.76rem;
	background:#fff;
	border-radius:0.16rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	color:#000;
	padding:0.32rem 0.4rem 0;
	box-sizing: border-box;
	.modelTitle{
		color:#000;
		font-weight: bold;
		font-size:0.3rem;
	}
	.modelMiddleWrap{
		flex: 1;
		margin-top: 0.48rem;
		.modelMiddle{
			width:100%;
			display: flex;
			padding: 0 0.1rem 0 0.24rem;
			background-color: #F2F4F9;
			border-radius: 0.08rem;
			box-sizing:border-box;
			align-items:center;
			img{
				height: 0.6rem;
				width: 1.7rem;
			}
			/deep/ .van-field__control{
				font-size:0.3rem;
			}
			/* border-bottom:0.02rem solid #eee; */
		}
	}
	.modelLine{
		width: 100%;
		height: 1px;
		background-color:#898DA7;
		position: absolute;
		left: 0;
		bottom:1.06rem;
	}
	.btns{
		width:100%;
		font-size: 0.3rem;
		line-height: 0.45rem;
		color:#071683;
		font-weight: bold;
		letter-spacing: 0.04rem;
		text-align: center;
		padding: 0.3rem 0;
	}
	
}
/deep/ .formContent .van-cell{
	background:none;
	height: 0.8rem;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0.30rem 0;
	border-bottom: 1px solid rgba(137, 141, 167, .3);
}
/deep/ .modelCenter .van-cell{
	background:none;
	height: 0.8rem;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0.30rem 0;
}
/deep/ .van-checkbox__label{
	color: #898DA7;
}
/deep/ .van-cell::after{
	border:0;
}
</style>
