<template>
  <div
    v-if="showTwo"
    class="menu"
    @touchstart="touchstart"
    @touchmove="touchmove"
    @touchend="touchend"
  >
    <div class="menuL" @click="$emit('update:show', false)" v-if="show"></div>
    <!-- <div class="menuLB" @click="$emit('update:show', false)" v-if="show"></div> -->
    <div class="main" :class="token ? 'main1' : 'main2'">
      <div class="animation" v-if="token">
        <div class="animationMain">
          <div class="animationList">
            <div
              class="animationItem"
              v-for="(item, index) in cqListSort"
              :key="index"
            >
              <van-image
                class="artworkImages"
                :src="item.artworkImages"
                fit="cover"
              ></van-image>
            </div>
          </div>
          <div class="animationList">
            <div
              class="animationItem"
              v-for="(item, index) in cqListReverse"
              :key="index"
            >
              <van-image
                class="artworkImages"
                :src="item.artworkImages"
                fit="cover"
              ></van-image>
            </div>
          </div>
        </div>
      </div>
      <div class="content" :class="token ? 'contents' : 'contents1'">
        <div class="info" v-if="!token" @click="$router.push('/login')">
          <div class="haed">
            <img
              class="haedImg"
              :src="userHeadimgurl"
              fit="cover"
            ></img>
          </div>
          <div class="nickname1">登录/注册</div>
        </div>
        <div class="info" v-if="token">
          <div class="haed">
            <img
              class="haedImg"
              :src="userInfos.userHeadimgurl || userHeadimgurl"
              fit="cover"
            ></img>
          </div>
          <div class="nickname">{{ userInfos.userNickname || "昵称" }}</div>
        </div>
        <div class="list">
          <div
            class="item"
            v-for="item in list"
            :key="item.type"
            @click="itemClick(item.type)"
          >
            <img :src="item.icon" class="icon" alt="" />
            <div class="name">{{ item.name }}</div>
          </div>
          <div
            class="item"
            @click="token ? $router.push('/mine') : $router.push('/login')"
          >
            <img :src="mineIcon" class="icon" alt="" />
            <div class="name">个人主页</div>
          </div>
        </div>
        <div class="footer" v-if="token">
          <div class="quit" @click="logout">退出登录</div>
          <div class="logo"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Dialog, Toast } from "vant";
export default {
  name: "menus",
  props: {
    show: Boolean,
    tabType: String,
    cqList: Array,
  },
  data() {
    return {
      cqListSort: [],
      cqListSortOrigin: [],
      cqListReverse: [],
      cqListReverseOrigin: [],
      showTwo: false,
      moveX: 0,
      userHeadimgurl: "https://file-adwx.oss-cn-hangzhou.aliyuncs.com/newnewImg/images/index/未登录头像.png",
      list: [
        {
          type: "getCq",
          name: "最新藏品",
          icon: "https://file-adwx.oss-cn-hangzhou.aliyuncs.com/newnewImg/images/index/icon_40_new.svg",
        },
        {
          type: "getSaleTime",
          name: "发售日历",
          icon: "https://file-adwx.oss-cn-hangzhou.aliyuncs.com/newnewImg/images/index/icon_40_calendar.svg",
        },
        {
          type: "getThemeList",
          name: "系列专题",
          icon: "https://file-adwx.oss-cn-hangzhou.aliyuncs.com/newnewImg/images/index/icon_40_topics.svg",
        },
        {
          type: "getAuthors",
          name: "创作家",
          icon: "https://file-adwx.oss-cn-hangzhou.aliyuncs.com/newnewImg/images/index/icon_40_artist.svg",
        },
      ],
      mineIcon:
        "https://file-adwx.oss-cn-hangzhou.aliyuncs.com/newnewImg/images/index/icon_40_person.svg",
      requestAnimationFrameId: null,
    };
  },
  computed: {
    userInfos() {
      return this.$store.state.userInfos;
    },
    token() {
      return this.$store.state.token;
    },
    possessorConfig() {
      let datas = this.$store.state.possessorConfig;
      return datas;
    },
  },
  watch: {
    show(newValue) {
      if (newValue) {
        this.showTwo = true;
        document.body.style.overflow = "hidden";
        let val = JSON.parse(JSON.stringify(this.cqList));
        let val1 = JSON.parse(JSON.stringify(val)).reverse();
        this.cqListSort = [...val, ...val];
        this.cqListReverse = [...val1, ...val1];
        this.cqListSortOrigin = JSON.parse(JSON.stringify(this.cqListSort));
        this.cqListReverseOrigin = JSON.parse(
          JSON.stringify(this.cqListReverse)
        );
        this.$nextTick(() => {
          this.animationStart();
        });
      } else {
        setTimeout(() => {
          this.showTwo = false;
          document.body.style.overflow = "";
          cancelAnimationFrame(this.requestAnimationFrameId);
          this.cqListSort = [];
          this.cqListReverse = [];
        }, 500);
      }
    },
  },
  methods: {
    menuLClick(e) {
      if (120 <= e.clientY && e.clientY <= 248) {
        console.log(e.clientY);
      } else {
        this.$emit("update:show", false);
      }
    },
    animationStart() {
      let width = window.innerWidth;
      let listDom = document.querySelectorAll(".animationList");
      if (!listDom.length) return;
      let offsetWidth = listDom[0].offsetWidth;
      const loop = () => {
        for (let item of listDom) {
          let marginLeft = listDom[0].style.marginLeft;
          marginLeft = !marginLeft ? 0 : Number(marginLeft.split("px")[0]);
          marginLeft -= 0.5;
          item.style.marginLeft = marginLeft + "px";
          let num = this.cqListSort.length / (this.cqList.length * 2);
          if (parseInt((Math.abs(marginLeft) + width) / offsetWidth) === num) {
            this.cqListSort = [...this.cqListSort, ...this.cqListSortOrigin];
            this.cqListReverse = [
              ...this.cqListReverse,
              ...this.cqListReverseOrigin,
            ];
          }
        }
        this.requestAnimationFrameId = requestAnimationFrame(loop);
      };
      loop();
    },
    //退出登录
    logout() {
      Dialog.alert({
        message: "是否确认退出登录？",
        showCancelButton: true,
        confirmButtonColor: "#071683",
        cancelButtonColor: "#898DA7",
      }).then(() => {
        this.$store.commit("USER_UPDATE", null);
        this.$store.commit("TOKEN_UPDATE", null);
        localStorage.removeItem("userInfos");
        localStorage.removeItem("linkphone");
        localStorage.removeItem("token");
        localStorage.removeItem("firstUrl");
        this.$emit("update:show", false);
        // localStorage.clear();
        Toast("退出成功");
      });
    },
    itemClick(e) {
      this.$emit("update:show", false);
      this.$emit("update:tabType", e);
      this.$emit("dataInit");
    },
    touchstart(e) {
      this.moveX = e.touches[0].clientX;
    },
    touchmove(e) {
      let x = e.touches[0].clientX;
      if (x > this.moveX) {
        this.$emit("update:show", false);
      }
    },
    touchend(e) {
      this.moveX = 0;
    },
  },
};
</script>

<style lang="less" scoped>
.menu {
  position: relative;
  width: 70vw;
  height: 100vh;
  right: 0;
  div {
    color: #ffffff !important;
  }
  .menuL {
    // background-color: pink;
    position: absolute;
    width: 30vw;
    height: 100vh;
    top: 0;
    left: -30vw;
    z-index: 999;
  }
  .menuLB {
    // background-color: pink;
    position: absolute;
    width: 30vw;
    height: 70vh;
    bottom: 0;
    left: -30vw;
    z-index: 999;
  }

  .main1 {
    background: linear-gradient(167.96deg, #2338b0 0%, #081783 100%);
  }
  .main2 {
    background: url("https://file-adwx.oss-cn-hangzhou.aliyuncs.com/newnewImg/images/index/菜单栏未登录背景.png")
      no-repeat;
    background-size: cover;
    // background-size: auto 100%;
  }
  .main {
    width: 70vw;
    height: 100vh;
    overflow: hidden;
    position: relative;
    .animationMain {
      transform: matrix(1, -0.45, 0, 0.93, 0, 0);
    }
    .animationList {
      display: inline-flex;
      height: 2.16rem;
      .animationItem {
        min-width: 1.92rem;
        width: 1.92rem;
        height: 1.92rem;
        min-height: 1.92rem;
        border-radius: 0.08rem;
        overflow: hidden;
        margin-right: 0.24rem;

        .artworkImages {
          width: 100%;
          height: 100%;
        }
      }
    }
    .contents {
      backdrop-filter: blur(1px);
      background: rgba(0, 36, 149, 0.8);
    }
    .contents1 {
      background: linear-gradient(90deg, rgba(0, 0, 0, .3) 0%, rgba(0, 0, 0, 0) 100%);
    }
    .content {
      padding-top: 1.32rem;
      padding-left: 1.48rem;
      padding-right: 0.3rem;
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }
    .info {
      margin-bottom: 1.35rem;
    }
    .haed {
      width: 1.36rem;
      height: 1.36rem;
      overflow: hidden;
       clip-path: polygon(0 15%, 15% 0, 100% 0, 100% 85%, 85% 100%, 0 100%);
      border-radius: 0.28rem 0.08rem 0.31rem;
      margin-bottom: 0.16rem;
      .haedImg {
				object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
    .nickname1 {
			// border-radius: 0 0.08rem;
			// clip-path: polygon(0 28%, 10% 0, 100% 0, 100% 72%, 90% 100%, 0 100%);
			display: flex;
			align-items: center;
			justify-content: center;
			// background: #fa4615;
			width: 1.49rem;
			height: 0.56rem;
			font-size: 0.26rem;
			background-image: url('https://file-adwx.oss-cn-hangzhou.aliyuncs.com/newnewImg/images/index/登录注册背景.png');
			background-size: 100% 100%;
			background-repeat: no-repeat;
    }
    .nickname {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-weight: 600;
      font-size: 0.32rem;
    }
    .list {
      margin-bottom: 0.56rem;
      .item {
        display: flex;
        align-items: center;
        margin-bottom: 0.88rem;
        .icon {
          width: 0.4rem;
          height: 0.4rem;
          margin-right: 0.32rem;
        }
        .name {
          font-size: 0.3rem;
        }
      }
    }
    .footer {
      border-top: 1px solid rgba(255, 255, 255, 0.3);
    }
    .quit {
      font-size: 0.26rem;
      line-height: 0.36rem;
      margin: 0.4rem 0;
    }
    .logo {
      width: 2.74rem;
      height: 0.32rem;
      background: url("https://file-adwx.oss-cn-hangzhou.aliyuncs.com/newnewImg/images/index/内容_logo_40h_菜单栏_已登录.png")
        no-repeat;
      background-size: 100% 100%;
    }
  }
}
</style>