<template>
  <section 
    class="barrage-wrapper" 
    ref="barrageWrapper">
    <div class="barrage-main">
      <div 
        class="barrage-main-dm ani-running"
        ref="barrageMainDm">
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    // 弹幕源数组
    arr: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  data () {
    return {
      // 弹幕数组
      barrages: [],
      // dom池
      domPool: [],
      // intervalDM
      intervalDM: null,
      // 滚动弹幕的通道
      hasPosition: [],
      // 弹幕容器
      barrageMainDm: null,
      // 弹幕容器宽度
      barMainWidth:1920,
      //容易高度
      barMainHeight:1080,
      // 自定义弹幕样式属性列表
      dmStyles: [
        'color',
        'fontSize'
      ]
    };
  },
  created () {
    this.barrages = JSON.parse(JSON.stringify(this.arr));
  },
  mounted () {
    this.barrageMainDm = this.$refs.barrageMainDm;
    // 缓存容器宽度
    this.barMainWidth = this.barrageMainDm.clientWidth;
    this.barMainHeight =  this.barrageMainDm.clientHeight;
    // 初始化弹幕dom组
    this.init();
    // 开始播放弹幕
    this.playDm();
    // 注册页面监听器
    document.addEventListener("visibilitychange", this.visibilitychangeFn);
  },
  methods: {
    visibilitychangeFn () {
      if (!document.hidden) {
        //处于当前页面
        this.playDm();
      } else {
        clearInterval(this.intervalDM);
        this.intervalDM = null;
      }
    },
    init () {
      // 先new一些div 重复利用这些DOM
      for (let j = 0; j < 10; j++) {
          // 初始化dom
          let dom = document.createElement('div');
          // 初始化dom的位置 通过设置className
          dom.className = 'barrage-item';
          dom.style.transform = `translate3d(0,${this.barMainHeight}px,0)`
          // DOM的通道是固定的 所以设置好top就不需要再改变了
          dom.style.left = this.getRandomPos()+'rem';
          let size = this.getRandomSize();
          dom.style.width = size+'rem';
          dom.style.height = size+'rem';
          // 每次到animationend结束的时候 就是弹幕划出屏幕了 将DOM位置重置 再放回DOM池
          dom.addEventListener('animationend', (e) => {
            // 初始化dom样式
            dom.className = 'barrage-item';
            dom.style.transform = `translate3d(0,${this.barMainHeight}px,0)`
            dom.style.animation = null;
            dom.innerHTML=null;
            // 清空自定义样式
            this.dmStyles.forEach(key => {
              dom.style[key] = null;
            })
            this.domPool[j].isEnd = false;
          });
          let obj = {
            el: dom,
            isEnd: false,
          };
        this.domPool.push(obj);
      }
    },
    //随机获取尺寸
		getRandomSize(){
      let arr= [1.43,2.45,3.25,4.27];
      let index =  Math.floor((Math.random()*arr.length)); 
      return arr[index];
    },
    //获取随机位置
    getRandomPos(){
      let dw = document.body.clientWidth*2-200;
      let pos= Math.floor(Math.random()*dw);
      return pos/100;
    },
    /**
    * 根据DOM和弹幕信息 发射弹幕
    */
    shootDanmu (domItem,img) {
      //获取dom
      let dom = domItem.el;
      // 是否已经使用的dom
      if (!domItem.isEnd && this.barrageMainDm) {
        domItem.isEnd = true;
        this.barrageMainDm.appendChild(domItem.el);
      }
      dom.innerHTML = `<img src="${img.url}" />`;
      dom.className = 'barrage-item';
      dom.style.animation = `barrage-run 16s linear`;
    },
    // 获取空闲通道中空闲的dom
    getFreeChannelDom () {
      let item;
      item = this.domPool.find(it => !it.isEnd)
      return item
    },
    // 暂停弹幕
    pauseDm () {
      if (this.intervalDM) {
        clearInterval(this.intervalDM)
        this.intervalDM = null;
      }
    },
    // 播放弹幕
    playDm () {
      // 每隔1ms从弹幕池里获取弹幕（如果有的话）并发射
      let self = this; // 这里取一个self this 为了方便调试的时候看到this具体内容
      self.intervalDM = setInterval(() => {
        if(self.barrages.length ==0){
          self.barrages = JSON.parse(JSON.stringify(this.arr))
        }
        if (self.barrages.length > 0) {
          let domItem = self.getFreeChannelDom();
          let obj = self.barrages.shift();
            if (domItem) {
              // let danmu = self.barrages.shift();
              self.shootDanmu(domItem,obj);
            }
        }
      },3000);
    }
  },
  watch:{
    arr (list) {
      this.barrages = JSON.parse(JSON.stringify(list));
    }
  }

};
</script>
<style  lang="less">
.barrage-wrapper {
  z-index: 1;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: transparent;
  .barrage-item {
    position: absolute;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 0.1rem;
      clip-path: polygon(0 10%, 10% 0, 100% 0,100% 90%, 90% 100%, 0 100%);
    }
  }
  .top-item {
    z-index: 100;
  }
  .barrage-main {
    /* border: 2px solid blue; */
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    // background: #000;
  }
  .barrage-main-dm {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
}
@keyframes barrage-run {
  0% {
    // transform: translate3d(0, 0, 0);
  }

  100% {
    transform: translate3d(0, -200%, 0);
  }
}
@keyframes barrage-fade {
  0% {
    visibility: visible;
    // opacity: 1;
  }
  100% {
    visibility: hidden;
    // opacity: 0;
  }
}
</style>
