import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/index.vue'
import Login from '../views/login.vue'

Vue.use(VueRouter)

//解决vue路由重复导航错误
//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}

const routes = [{
		path: '/',
		name: 'index',
		component: Index,
		meta: {}
	},
	{
		path: '/login',
		name: 'login',
		component: Login,
		meta: {}
	},
	//个人中心
	{
		path: '/mine',
		name: 'mine',
		component: () => import("@/views/minePage/mine.vue"),
		meta: {
			requireAuth: true, //判断是否需要登录可访问
		}
	},
	// 交易列表
	{
		path: '/orderList',
		name: 'orderList',
		component: () => import("@/views/minePage/order/orderList.vue"),
		meta: {
			requireAuth: true, //判断是否需要登录可访问
		}
	},
	// 实名认证
	{
		path: '/attestation',
		name: 'attestation',
		component: () => import("@/views/minePage/attestation.vue"),
		meta: {
			// requireAuth: true, //判断是否需要登录可访问
		}
	},
	// 交易支付
	{
		path: '/orderPayment',
		name: 'orderPayment',
		component: () => import("@/views/minePage/order/orderPayment.vue"),
		meta: {
			requireAuth: true, //判断是否需要登录可访问
		}
	},
	// 转赠确认支付
	{
		path: '/donationConfirmPage',
		name: 'donationConfirmPage',
		component: () => import("@/views/minePage/donation/donationConfirmPage.vue"),
		meta: {
			requireAuth: true, //判断是否需要登录可访问
		}
	},
	{
		path: '/AliPayPage',
		name: 'AliPayPage',
		component: ()=>import('@/views/minePage/order/AliPayPage.vue'),
		meta: {
			// requireAuth: true, //判断是否需要登录可访问
		},
	},
	// 订单详情
	{
		path: '/DiscoveryCollectionExchangeStatus',
		name: 'orderInfos',
		component: () => import("@/views/minePage/order/orderInfos.vue"),
		meta: {
			requireAuth: true, //判断是否需要登录可访问
		}
	},
	// 积分列表
	{
		path: '/integral',
		name: 'integral',
		component: () => import("@/views/minePage/integral.vue"),
		meta: {
			requireAuth: true, //判断是否需要登录可访问
		}
	},
	// 获取积分
	{
		path: '/getIntegral',
		name: 'getIntegral',
		component: () => import("@/views/minePage/integralInfos.vue"),
		meta: {
			requireAuth: true, //判断是否需要登录可访问
		}
	},
	//新品推荐列表页面
	{
		path: '/newProductList',
		name: 'newProductList',
		component: () => import("@/views/newProductList.vue"),
		meta: {},
	},
	//创作家列表页面
	{
		path: '/creatorList',
		name: 'creatorList',
		component: () => import("@/views/creatorList.vue"),
		meta: {},
	},
	//创作家详情页面
	{
		path: '/creatorDetail',
		name: 'creatorDetail',
		component: () => import("@/views/creatorDetail.vue"),
		meta: {},
	},
	//藏品详情页面
	{
		path: '/collectionDetail',
		name: 'collectionDetail',
		component: () => import("@/views/collectionDetail.vue"),
		meta: {},
	},
	//藏品海报页面
	{
		path: '/posterPage',
		name: 'posterPage',
		component: () => import("@/views/posterPage.vue"),
		meta: {}
	},
	//受赠海报页面
	{
		path: '/presentPoster',
		name: 'presentPoster',
		component: () => import("@/views/presentPoster.vue"),
		meta: {}
	},
	//其他页面
	{
		path: '/othersPage',
		name: 'othersPage',
		component: () => import("@/views/othersPage.vue"),
		meta: {},
	},
	//邀请好友加入
	{
		path: '/join',
		name: 'join',
		component: () => import("@/views/minePage/invitation/join.vue"),
		meta: {},
	},
	//邀请好友详情
	{
		path: '/inviteDetail',
		name: 'inviteDetail',
		component: () => import("@/views/minePage/invitation/inviteDetail.vue"),
		meta: {},
	},
	//主题展区
	{
		path: '/theme',
		name: 'themeArea',
		component: () => import('@/views/themePage/themeArea.vue')
	},
	//主题详情
	{
		path: '/theme/detail',
		name: 'themeDetail',
		component: () => import('@/views/themePage/themeDetail.vue')
	},
	//个人收藏
	{
		path: '/collection',
		name: 'collectionList',
		component: () => import('@/views/minePage/collection/collectionList.vue'),
		meta: {
			requireAuth:true
		},
	},
	//收藏详情
	{
		path: '/collection/detail',
		name: 'collectionDetail',
		component: () => import('@/views/minePage/collection/collectionDetail.vue'),
		meta: {
			requireAuth:true,
			scrollToTop: true
		},
	},
	//卡券
	{
		path: '/prizesList',
		name: 'prizesList',
		component: () => import('@/views/minePage/prizes/prizesList.vue'),
		meta: {
			requireAuth:true
		},
		
	},
	{
		path: '/payStatus',
		name: 'payStatus',
		component: () => import('@/views/minePage/order/payStatus.vue'),
		meta: {
			// requireAuth:true
		},
	},
	//banner跳转 自定义
	{
		path: '/showImg',
		name: 'showImg',
		component: () => import('@/views/minePage/showImg.vue'),
		meta: {
			// requireAuth:true
		},
	},
	//合成记录
	{
		path: '/synthesis/list',
		name: 'synthesisList',
		component: () => import('@/views/minePage/synthesis/list.vue'),
		meta: {
			requireAuth:true
		},
	},
	//我要合成
	{
		path: '/synthesis/detail',
		name: 'synthesisDetail',
		component: () => import('@/views/minePage/synthesis/detail.vue'),
		meta: {
			requireAuth:true
		},
	},
]

const router = new VueRouter({
	// mode: 'history',
	base: process.env.BASE_URL,
	routes
})

router.afterEach((to, from) => {
	// 当页面为打开的第一个页面时，url添加进localStorage
	if (!window.sessionStorage.firstUrl) {
		window.sessionStorage.firstUrl = window.location.href
	}
})

export default router