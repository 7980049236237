<template>
	<div class="contents" id="scrollview">
		<div class="contentsL" :class="'contentsL' + menusShow">
			<div id="top">
				<!-- 头部 -->
				<div class="header">
					<div class="logo"></div>
					<div class="menu" @click="menusShow = true" v-if="!menusShow"></div>
				</div>
				<!-- 公告消息 -->
				<div class="notice" v-if="notice.length">
					<div class="noticeIcon"></div>
					<div class="noticeMain">
						<van-swipe class="my-swipe" style="height: 0.4rem;" :autoplay="5000" :show-indicators="false"
							:touchable='false' :vertical="true">
							<van-swipe-item v-for="item in notice" :key="item.id">{{
                item.noticeTitle
              }}</van-swipe-item>
						</van-swipe>
					</div>
				</div>
				<!-- 轮播图 -->
				<div class="swiper-container">
					<van-swipe class="swiper-slide" :autoplay="5000" :show-indicators="false">
						<van-swipe-item v-for="(item,index) in bannerList" :key="item.id" @click="bannerGoNext(item)">
							<img :src="item.imageUrls" class="" alt="" />
						</van-swipe-item>
					</van-swipe>
				</div>
			</div>
			<div id="main" v-show="topLoading">
				<!-- tab -->
				<div class="tab" id="tab">
					<div class="itemMain" v-for="item in tabList" :key="item.type" @click="tabClick(item.type)">
						<div class="itemName" :class="item.type === tabType ? 'itemNameAct' : ''">
							{{ item.name }}
						</div>
					</div>
				</div>
				<div class="tab tabFixed" v-show="tabFixed">
					<div class="itemMain" v-for="item in tabList" :key="item.type" @click="tabClick(item.type)">
						<div class="itemName" :class="item.type === tabType ? 'itemNameAct' : ''">
							{{ item.name }}
						</div>
					</div>
				</div>
				<div class="list">
					<!-- 藏品 -->
					<van-list v-if="cqList.length > 0 && tabType === 'getCq'" v-model="listLoading" :finished="finished"
						:finished-text="finishedText" @load="getCq" :offset="50">
						<div class="cqList">
							<div class="shadow-box-cq">
								<div>
									<div class="item" v-for="item in cqList" :key="item.artworkCode"
										:class="{'blueItem':(item.isPresale && item.metaIpProduct.saleStatus == 'sale') || (item.metaIpProduct.saleStatus == 'sale' && item.isSellup != 1 && !item.isSellout && !item.isPresale)}"
										@click="toDetailPage(item.artworkCode)">
										<img v-if="(item.isPresale && item.metaIpProduct.saleStatus == 'sale') || (item.metaIpProduct.saleStatus == 'sale' && item.isSellup != 1 && !item.isSellout && !item.isPresale)"
											class="bg-img"
											src="https://file-adwx.oss-cn-hangzhou.aliyuncs.com/newnewImg/images/index/推荐藏品外框.png"
											alt="">
										<div class="label" v-if="item.metaIpProduct.saleStatus == 'nosale'">已停售</div>
										<img v-else-if="item.isSellup == 1 || (item.isSellout && item.metaIpProduct.saleStatus != 'nosale')"
											class="soldout-img" :src="possessorConfig!=null?possessorConfig.soldOutUrl:''"
											alt="">
										<div class="label labels"
											v-else-if="item.isPresale && item.metaIpProduct.saleStatus == 'sale'">
											<template v-if="item.countDownTime[0] == '00'">
												即将开售
												<div class="labelr"></div>
												{{ item.countDownTime[0] }}天{{ item.countDownTime[1] }}:{{
												                    item.countDownTime[2]
												                  }}:{{ item.countDownTime[3] }}
											</template>
											<template v-else>
												敬请期待 {{$dayjs(item.metaIpProduct.saleTime).format('MM.DD HH:mm')}} 开售
											</template>
										</div>
										<div class="label labels"
											v-else-if=" item.metaIpProduct.saleStatus == 'sale' && item.isSellup != 1 && !item.isSellout && !item.isPresale">
											出售中</div>
										<!-- <div class="label" v-if=" item.isSellup == 1 || (item.isSellout && item.metaIpProduct.saleStatus != 'nosale')">已售罄</div> -->
															
										<img class="artworkImages" :src="item.artworkImages" fit="cover"></img>
										<!-- <div class="hotImg"></div> -->
										<div class="main">
											<div class="mains">
												<div class="name">{{ item.artworkName }}</div>
												<div class="priceAndScore">
													<span v-if="item.metaIpProduct.payMethod === 'cash'">
														<template v-if="item.price[1] != 0 && item.price[1] != '00'">
															<span class="price1">￥{{ item.price[0] }}</span>
															<span class="price2">.{{ item.price[1] }}</span>
														</template>
													</span>
													<span v-else>
														<template v-if="item.chargeScore">
															<span class="price1">{{ item.chargeScore }}</span>
															<span class="price3">{{possessorConfig.scoreTypeName}}</span>
														</template>
														
													</span>
												</div>
											</div>
											<div class="mains">
												<div class="author">
													<van-image class="authorHead" :src="item.author.headUrl" fit="cover">
													</van-image>
													<div class="authorName">{{ item.author.authorName }}</div>
												</div>
												<div class="num">
													限量<span>{{
									          (item.stockNum || 0) + (item.exchangeNum || 0)
									        }}</span>份
												</div>
											</div>
										</div>
									</div>
								</div>	
								<img style="width: 3.42rem;margin: 0.5rem 1.6rem" src="https://file-adwx.oss-cn-hangzhou.aliyuncs.com/newnewImg/images/02mine/底部logo.png" alt="">
							</div>
							
						</div>
						
						
					</van-list>
					<!-- 发售日历 -->
					<div class="saleTime" v-if="saleTimeList.length > 0 && tabType === 'getSaleTime'">
						<div>
							<div class="item" v-for="item in saleTimeList" :key="item.month">
								<div class="title">
									<div class="month">{{ item.month }}</div>
									<div class="monthIcon"></div>
								</div>
								<div>
									<div class="itemList" v-for="itemList in item.list" :key="itemList.saleTime">
										<div class="itemListTitle">{{ itemList.saleTime }}</div>
										<div class="itemListSonMain" v-for="itemListSon in itemList.saleData"
											:key="itemListSon.artworkCode" @click="toDetailPage(itemListSon.artworkCode)">
											<div class="img-box">
												<div class="img">
													<img class="artworkImages" :src="itemListSon.artworkImages"
														fit="cover">
												</div>
											</div>
											
											<div class="itemListSon">
												<div class="content">
													<div class="name">{{ itemListSon.artworkName }}</div>
													<div class="num">
														限量<span>{{
							                (itemListSon.stockNum || 0) +
							                (itemListSon.exchangeNum || 0)
							              }}</span>份
													</div>
													<div class="priceAndScore">
														<span v-if="
							                  itemListSon.metaIpProduct.payMethod === 'cash'
							                ">
															<span class="price1">￥{{ itemListSon.price[0] }}</span><span
																class="price2">.{{ itemListSon.price[1] }}</span>
														</span>
														<span v-else>
															<span class="price1">{{
							                  itemListSon.chargeScore
							                }}</span><span class="price3">{{
							                  possessorConfig.scoreTypeName
							                }}</span>
														</span>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<img style="width: 3.42rem;margin: 0.5rem 1.6rem" src="https://file-adwx.oss-cn-hangzhou.aliyuncs.com/newnewImg/images/02mine/底部logo.png" alt="">
					</div>
					<!-- 系列专题 -->
					<van-list v-if="themeList.length > 0 && tabType === 'getThemeList'" v-model="listLoading"
						:finished="finished" :finished-text="finishedText" @load="getThemeList" :offset="50">
						<div class="theme-shadow">
							<div>
								<div class="theme" v-for="item in themeList" :key="item.composeCode"
									@click="themeClick(item.composeCode)">
									<van-image class="artworkImages" :src="item.composeImages" fit="cover"></van-image>
									<img class="theme-bg"
										src="https://file-adwx.oss-cn-hangzhou.aliyuncs.com/newnewImg/images/index/系列渐变深.png"
										alt="">
									<img class="theme-bg-line"
										src="https://file-adwx.oss-cn-hangzhou.aliyuncs.com/newnewImg/images/index/系列底部分割线.png"
										alt="">
									<div class="content">
										<div class="title">{{ item.composeName }}</div>
									</div>
								</div>
							</div>
							<img style="width: 3.42rem;margin: 0.5rem 1.6rem" src="https://file-adwx.oss-cn-hangzhou.aliyuncs.com/newnewImg/images/02mine/底部logo.png" alt="">
						</div>
					</van-list>
					<!-- 作家 -->
					<van-list v-if="authorsList.length > 0 && tabType === 'getAuthors'" v-model="listLoading"
						:finished="finished" :finished-text="finishedText" @load="getThemeList" :offset="50">
						<div class="shadow-box">
							<div class="authors" v-for="item in authorsList" :key="item.id"
								@click="$router.push('/creatorDetail?data=' + item.id)">
								<img class="artworkImages" :src="item.depictUrl" fit="cover"></img>
								
								<div class="content">
									<div class="img-box">
										<div class="img">
											<img class="headUrl" :src="item.headUrl" fit="cover"></img>
										</div>
									</div>
									<div class="info">
										<div class="name">{{ item.authorName }}</div>
										<div class="depict">{{ item.authorAbout }}</div>
									</div>
								</div>
							</div>
							<img style="width: 3.42rem;margin: 0.5rem 1.6rem" src="https://file-adwx.oss-cn-hangzhou.aliyuncs.com/newnewImg/images/02mine/底部logo.png" alt="">
						</div>
					</van-list>
					<!-- <div class="footer">
						<div class="footerImg"></div>
					</div> -->
					<img v-if="(cqList.length == 0 && tabType === 'getCq') || (saleTimeList.length == 0 && tabType === 'getSaleTime') || (themeList.length == 0 && tabType === 'getThemeList') || (authorsList.length == 0 && tabType === 'getAuthors')" 
					style="width: 3.42rem;margin: 0.5rem 2rem 1rem;position: absolute;bottom: 0;right: 0;" src="https://file-adwx.oss-cn-hangzhou.aliyuncs.com/newnewImg/images/02mine/底部logo.png" alt="">
				</div>
				
			</div>
			<div class="newUser" v-if="metaUserActivityRecord && isCloseNewUser">
				<div class="main">
					<div class="label"></div>
					<div class="close" @click="closeNewUser()"></div>
					<div class="title">注册成功赠送积分</div>
					<div class="content">
						<div class="icon"></div>
						<div class="icon1"></div>
						<div class="num">{{ metaUserActivityRecord.rewardNum }}</div>
					</div>
					<div class="btn" @click="metaUserActivityRecord = null">
						开启我的元宇宙
					</div>
					<div class="integral" @click="$router.push('/integral?number=' + userInfos.score)">
						新人礼包到个人中心查看
					</div>
				</div>
			</div>
		</div>
		<menus :show.sync="menusShow" :tabType.sync="tabType" @dataInit="dataInit" :cqList="cqList" />
	</div>
</template>



<script>
	import menus from "@/components/menus.vue";
	import Swiper from "swiper";
	import "swiper/css";
	import api from "../assets/http/api";
	import {
		Toast
	} from "vant";
	import * as dayJs from "dayjs";
	export default {
		// eslint-disable-next-line vue/multi-word-component-names
		name: "index",
		// eslint-disable-next-line vue/no-unused-components
		components: {
			menus,
		},
		data() {
			return {
				time: 30 * 60 * 60 * 1000,
				bannerList: [], //banner数组
				cqList: [], //藏品列表
				saleTimeList: [], // 销售日历列表
				themeList: [], //获取主题展区
				themeListt: [], //获取主题展区
				authorsList: [], //创作家
				swiperIndex: 0,
				mySwiper: null,
				clipPath: "polygon(0% 3.5%, 3.5% 1%, 33% 1%, 36% 3%, 99% 3%, 99.5% 96%, 96% 99.5%, 0% 99.5%)",
				meauSHow: false,
				applyName: "",
				/* 公告消息 */
				notice: [],
				/* 导航tab */
				tabList: [{
						type: "getCq",
						name: "最新藏品"
					},
					{
						type: "getSaleTime",
						name: "发售日历"
					},
					{
						type: "getThemeList",
						name: "系列专题"
					},
					{
						type: "getAuthors",
						name: "创作家"
					},
				],
				/* tab当前选中 */
				tabType: "getCq",
				/* 数据当前页 */
				pageNum: 1,
				/* 列表是否在下载中 */
				listLoading: false,
				/* 是否加载完成 */
				finished: false,
				/* 加载文案*/
				finishedText: "",
				topLoading: false,
				tabOffsetTop: 0,
				tabFixed: false,
				menusShow: false,
				metaUserActivityRecord: null,
			};
		},
		computed: {
			token() {
				return this.$store.state.token;
			},
			possessorConfig() {
				let datas = this.$store.state.possessorConfig;
				return datas;
			},
			userInfos() {
				return this.$store.state.userInfos;
			},
			isCloseNewUser() {
				return localStorage.getItem('closeNewUser')
			}
		},
		mounted() {
			var hm1 = document.createElement("script");
			hm1.src = "https://www.googletagmanager.com/gtag/js?id=G-K4200DHE97";
			var s1 = document.getElementsByTagName("script")[0];
			s1.parentNode.insertBefore(hm1, s1);
			// 谷歌加载,不需要可删除
			window.dataLayer = window.dataLayer || [];

			function gtag() {
				dataLayer.push(arguments);
			}
			gtag("js", new Date());
			gtag("config", "G-K4200DHE97");

			this.init();
			window.addEventListener('scroll', this.handleScroll, true); // 监听页面滚动
			if (this.$route.query.type) {
				if (this.$route.query.type == 'getCq')
					return
				this.tabType = this.$route.query.type;
				this.dataInit();
			}
		},
		methods: {
			onTouchS(e) {
				// console.log("ssss",e)
			},
			onTouchM(e) {
				// console.log("mmmm",e)
			},
			onTouchE(e) {
				// console.log("eee",e)
			},

			/* 新用户 */
			isNewUser() {
				if (!this.token) return;
				this.metaUserActivityRecord = this.userInfos.metaUserActivityRecord;
			},
			//首次关闭之后不再出现
			closeNewUser() {
				this.metaUserActivityRecord = null
				localStorage.setItem('closeNewUser', 1);
			},
			themeClick(e) {
				this.$router.push("/theme/detail?code=" + e);
			},
			/* tab切换 */
			tabClick(e) {
				this.tabType = e;
				this.dataInit();
			},
			/* 加载 */
			loading() {
				this.listLoading = true;
				Toast.loading({
					duration: 0,
					message: "加载中...",
					forbidClick: true,
				});
			},
			/* 加载完成 */
			loadingColse() {
				this.listLoading = false;
				Toast.clear();
			},
			//倒计时
			finish() {
				this.getCq(); //获取藏品
			},
			//banner跳转
			bannerGoNext(dataset) {
				//  location.href = 'http://192.168.50.37:8080/#/?appid=782267727&phone=15117688607&possessor=33&sign=44'
				// return
				let redirectPage = dataset.redirectPage;
				let contentImages = dataset?.mallDivPage?.contentImages;
				let pageName =
					dataset?.mallDivPage?.pageName ||
					(this.possessorConfigres != null ?
						this.possessorConfigres.applyName :
						"");
				let artworkCode = dataset.redirectModule;
				if (redirectPage == "divPage" && contentImages) {
					this.$router.push({
						name: "showImg",
						query: {
							urls: contentImages,
							title: pageName,
							pageCode: dataset?.mallDivPage?.pageCode,
						},
					});
				} else if (redirectPage == "customerManage") {
					this.$router.push({
						name: "mine"
					});
				} else if (redirectPage == "artWork") {
					this.toDetailPage(artworkCode);
				}
			},
			//左侧菜单展示
			showModel(value) {
				this.meauSHow = value;
			},
			handleScroll() {
				var scrollTop = document.documentElement.scrollTop || document.body.scrollTop || window.pageYOffset
				// var offsetTop = document.querySelector('#tab').offsetTop
				// console.log(scrollTop)
				// 滚动条滚动的距离

				if (scrollTop >= this.tabOffsetTop) {
					let scrollStep = scrollTop - this.oldScrollTop;
					// 更新——滚动前，滚动条距文档顶部的距离
					this.oldScrollTop = scrollTop;
					if (scrollStep < 0) {
						this.tabFixed = false;
					} else {
						this.tabFixed = true;
					}

				} else {
					this.tabFixed = false;
				}
			},
			async init() {
				this.dataInit();
				await this.getNotice(); // 获取公告
				await this.getBanner(); //获取广告图
				this.topLoading = true;
				this.$nextTick(() => {
					this.tabOffsetTop = document.querySelector("#tab").getBoundingClientRect().top;
				});
				this.isNewUser();
			},
			/* 数据初始化 */
			dataInit() {
				this.finished = false;
				this.finishedText = "";
				this.pageNum = 1;
				this[this.tabType](); //获取藏品
				window.scrollTo(0, 0);
			},
			// 获取发售日历
			async getSaleTime() {
				this.loading();
				// this.saleTimeList.push({month:'888'},{month:'888'});
				// console.log("liruuuuu",this.saleTimeList)
				let year = new Date().getFullYear();
				let month = new Date().getMonth() + 1;
				let num = 6;
				const getData = async () => {
					let res = (
						await api.getIpSaleTime(String(year) + this.checkTime(month))
					).data;
					if (res.length) {
						this.getIpSaleDataList(res);
						this.loadingColse();
					} else {
						month += 1;
						if (month > 12) {
							year += 1;
							month = 1;
						}
						num -= 1;
						if (num < 1) {
							this.loadingColse();
							return;
						}
						getData();
					}
				};
				getData();
			},
			// 获取发售日历藏品
			async getIpSaleDataList(list) {
				let tempList = [];
				for (let item of list) {
					let obj = {};
					let month = item.split("-");
					obj.month = month[1] + "/" + month[2];
					obj.list = (await api.getIpSaleDataList(item.replace(/-/g, ""))).data;
					for (let j of obj.list) {
						for (let i of j.saleData) {
							if (i.price) {
								i.price = i.price.toFixed(2).split(".");
							}
						}
					}
					// console.log("llllll",obj.list)
					obj.list.sort((a,b)=>{
					let t1 = new Date(Date.parse(a.saleTime.replace(/:/g, "")))
					        let t2 = new Date(Date.parse(b.saleTime.replace(/:/g, "")))
					        return t2.getTime() - t1.getTime()
				  })
					tempList.push(obj);
				}
				tempList.sort((a,b)=>{
					let t1 = new Date(Date.parse(a.month.replace(/-/g, "/")))
					        let t2 = new Date(Date.parse(b.month.replace(/-/g, "/")))
					        return t2.getTime() - t1.getTime()
				})
				// console.log("liruuuuu",tempList)
				this.saleTimeList = tempList;
				
			},
			// 获取公告
			async getNotice() {
				if (!this.token) return;
				let isNotice = await api.getIsNotice();
				if (isNotice.code === 200) {
					let res = await api.getNotice();
					this.notice = res.rows;
				}
			},
			//获取广告图
			async getBanner() {
				let res = await api.getIndexBanner();
				this.bannerList = res.data;
				// this.$nextTick(() => {
				// 	let swiper = new Swiper(".swiper-container", {
				// 		spaceBetween: "3.2%",
				// 	});
				// });
			},
			//获取藏品
			getCq() {
				this.loading();
				let config = {
					pageNum: this.pageNum,
					pageSize: 10,
					orderByColumn: "a.sort",
					isAsc: "desc",
				};
				api
					.getIndexCp(config)
					.then(async (res) => {
						if (res.code === 200) {
							for (let i of res.rows) {
								i.author = (
									await api.getAuthorInfo({
										authorId: i.metaIpProduct.ipAuthor
									})
								).data;
								if (i.price) {
									i.price = i.price.toFixed(2).split(".");
								}
								if (i.stockNum - i.deductNum <= 0) {
									this.$set(i, "isSellout", true);
								}
								var now = new Date().getTime();
								var end = new Date(
									i.metaIpProduct.saleTime.replace(/\-/g, "/")
								).getTime();
								var leftTime = (end - now) / 1000;
								if (leftTime > 0) {
									let time = this.timef(leftTime).split(",");
									// console.log("shijian", time)
									this.$set(i, "countDownTime", time);
									this.$set(i, "leftTime", leftTime);

									this.$set(i, "isPresale", true);
								}
							}
							/* 倒计时 */
							res.rows.forEach((i) => {
								if (i.leftTime > 0) {
									let timer = setInterval(() => {
										i.leftTime--;
										let ct = this.timef(i.leftTime);
										let time = ct.split(",");

										if (i.leftTime < 0) {
											this.$set(i, "isPresale", false);
											clearInterval(timer);
										}

										this.$set(i, "countDownTime", time);
									}, 1000);
								}
							});
							this.cqList =
								this.pageNum === 1 ? res.rows : this.cqList.concat(res.rows);
							this.finishedText = this.cqList.length == 0 ? "暂无数据内容" : "";
							if (this.cqList.length >= res.total) {
								this.finished = true;
							} else {
								this.finished = false;
							}
							this.pageNum++; //页数+1
						}
					})
					.finally(() => {
						this.loadingColse();
					});
			},
			checkTime(i) {
				//将0-9的数字前面加上0，例1变为01
				if (i < 10) {
					i = "0" + i;
				}
				return i;
			},
			timef(leftTime) {
				var tt = "";
				var d, h, m, s;
				var ff = [];
				if (leftTime >= 0) {
					d = this.checkTime(Math.floor(leftTime / 60 / 60 / 24));
					h = this.checkTime(Math.floor((leftTime / 60 / 60) % 24));
					m = this.checkTime(Math.floor((leftTime / 60) % 60));
					s = this.checkTime(Math.floor(leftTime % 60));
					tt = `${d},${h},${m},${s}`;
				} else {
					tt = `00,00,00,00`;
				}
				return tt;
			},
			//获取主题展区
			getThemeList() {
				this.loading();
				api
					.getTheme({
						pageNum: this.pageNum,
						pageSize: 10,
					})
					.then(async (res) => {
						this.themeList =
							this.pageNum === 1 ? res.rows : this.themeList.concat(res.rows);
						this.finishedText = this.themeList.length == 0 ? "暂无数据内容" : "";
						if (this.themeList.length >= res.total) {
							this.finished = true;
						} else {
							this.finished = false;
						}
						this.pageNum++; //页数+1
						// this.themeList = this.themeList.splice(0,2)
					})
					.finally(() => {
						this.loadingColse();
					});
			},
			//获取创作家
			getAuthors() {
				this.loading();
				api
					.getAuthor({
						pageNum: this.pageNum,
						pageSize: 10,
					})
					.then((res) => {
						this.authorsList =
							this.pageNum === 1 ? res.rows : this.authorsList.concat(res.rows);
						this.finishedText =
							this.authorsList.length == 0 ? "暂无数据内容" : "";
						if (this.authorsList.length >= res.total) {
							this.finished = true;
						} else {
							this.finished = false;
						}
						this.pageNum++; //页数+1
					})
					.finally(() => {
						this.loadingColse();
					});
			},
			//新品跳转详情
			toDetailPage(code) {
				this.$router.push({
					path: "/collectionDetail",
					query: {
						data: JSON.stringify(code),
					},
				});
			},
			onChange(e) {
				this.swiperIndex = e;
			},
			//获取创作家名称
			getAuthorName() {
				let that = this;
				// if(this.themeList.length<=3){
				this.themeList.map(async (item, index) => {
					await api
						.getThemeDetail({
							composeCode: item.composeCode
						})
						.then(async (val) => {
							if (val.code == 200) {
								let ipAuthor =
									val.data.metaIpProductArtworkList[0].metaIpProduct.ipAuthor;
								await api.getAuthorInfo({
									authorId: ipAuthor
								}).then((i) => {
									if (i.code == 200) {
										that.$set(
											that.themeList[index],
											"authorName",
											i.data.authorName
										);
									}
								});
							}
						});
				});
			},
			splitArr(arr, num) {
				var res = [];
				for (var i = 0, len = arr.length; i < len; i += num) {
					res.push(arr.slice(i, i + num));
				}
				return res;
			},
			getTime(time) {
				let times =
					new Date(time.replace(/\-/g, "/")).getTime() -
					String(dayJs().valueOf());
				return times;
			},
		},
	};
</script>
<style>
</style>
<style lang="less" scoped>
	@import url("../assets/css/common.less");
	@import url("../assets/css/common2.less");

	div {
		box-sizing: border-box;
	}

	.contents {
		min-height: 100vh;
		min-width: 100vw;
		max-width: 100vw;
		background: #f2f4f9;
		display: inline-flex;

		.contentsL {
			min-height: 100vh;
			width: 100vw;
			transition-duration: 0.5s;
		}

		.contentsLtrue {
			margin-left: -70vw;
		}
	}

	.newUser {
		position: fixed;
		z-index: 999;
		left: 0;
		top: 0;
		background: rgba(0, 0, 0, 0.6);
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100vw;
		height: 100vh;

		.main {
			display: flex;
			flex-direction: column;
			align-items: center;
			width: 6.1rem;
			height: 5.56rem;
			background: #ffffff;
			border-radius: 0.16rem;
			position: relative;

			.label {
				width: 1.55rem;
				height: 1.55rem;
				background: url("https://file-adwx.oss-cn-hangzhou.aliyuncs.com/newnewImg/images/index/模块_角标签_新人礼包.png") no-repeat;
				background-size: 100% 100%;
				position: absolute;
				left: -0.08rem;
				top: -0.08rem;
			}

			.close {
				width: 0.4rem;
				height: 0.4rem;
				background: url("https://file-adwx.oss-cn-hangzhou.aliyuncs.com/newnewImg/images/index/icon_40_close.svg") no-repeat;
				background-size: 100% 100%;
				position: absolute;
				right: 0.16rem;
				top: 0.16rem;
			}

			.title {
				font-weight: 600;
				font-size: 0.3rem;
				line-height: 0.45rem;
				margin: 0.32rem 0 0.54rem 0;
			}

			.content {
				width: 4.8rem;
				height: 1.73rem;
				display: flex;
				align-items: center;
				justify-content: center;
				background: #f2f4f9;
				border-radius: 8px;
				clip-path: polygon(0 17%, 6% 0, 100% 0, 100% 83%, 94% 100%, 0 100%);

				.icon {
					width: 0.72rem;
					height: 0.72rem;
					background: url("https://file-adwx.oss-cn-hangzhou.aliyuncs.com/newnewImg/images/index/icon_72_dcoin.svg") no-repeat;
					background-size: 100% 100%;
				}

				.icon1 {
					width: 0.4rem;
					height: 0.4rem;
					background: url("https://file-adwx.oss-cn-hangzhou.aliyuncs.com/newnewImg/images/index/icon_40_ride.svg") no-repeat;
					background-size: 100% 100%;
					margin: 0 0.25rem;
				}

				.num {
					color: #071683;
					font-weight: 600;
					font-size: 1.2rem;
				}
			}

			.btn {
				width: 4.8rem;
				height: 0.88rem;
				background: #071683;
				font-weight: 600;
				font-size: 0.32rem;
				color: #ffffff;
				display: flex;
				align-items: center;
				justify-content: center;
				margin-top: 0.64rem;
				margin-bottom: 0.24rem;
				clip-path: polygon(0 34%, 6% 0, 100% 0, 100% 66%, 94% 100%, 0 100%);
			}

			.integral {
				font-size: 0.26rem;
				color: #898da7;
			}
		}
	}

	#top {
		.header {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100vw;
			padding: 0.68rem 0;
			position: relative;

			.logo {
				width: 3.42rem;
				height: 0.4rem;
				background: url("https://file-adwx.oss-cn-hangzhou.aliyuncs.com/newnewImg/images/index/内容_logo_40h_黑色_首页头部.png") no-repeat;
				background-size: 100% 100%;
			}

			.menu {
				position: absolute;
				width: 0.4rem;
				height: 0.4rem;
				right: 0.4rem;
				top: 0.68rem;
				background: url("https://file-adwx.oss-cn-hangzhou.aliyuncs.com/newnewImg/images/index/icon_40_menu.svg") no-repeat;
				background-size: 100% 100%;
			}
		}

		.notice {
			width: 100vw;
			padding: 0 0.4rem;
			display: inline-flex;
			align-items: center;

			.noticeIcon {
				width: 0.4rem;
				height: 0.4rem;
				background: url("https://file-adwx.oss-cn-hangzhou.aliyuncs.com/newnewImg/images/index/icon_40_notice.svg") no-repeat;
				background-size: 100% 100%;
				margin-right: 0.08rem;
				margin-bottom: 0.05rem;
				flex-shrink: 0;
			}

			.noticeMain {
				flex: 1;
			}

			.van-swipe-item {
				width: 100%;
				font-size: 0.3rem;
				color: #898da7;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
		}

		.swiper-container {
			margin: 0.2rem 0;
			padding: 0 0.36rem;
			width: 100vw;
			overflow: hidden;
			height: 2.54rem;

			.swiper-slide img {
				width: 98%;
				height: 100%;
				margin-left: 1%;
			}
		}
	}

	#main {
		padding: 0 0.4rem 0.7rem 0.4rem;

		// overflow: scroll;
		.list{
			// position: relative;
			// min-height: 40vh;
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
		}
		.tab {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;
			height: 1.08rem;

			.itemMain {
				// width: 23.9%;
				display: flex;
				justify-content: center;
				position: relative;
			}

			.itemName {
				font-size: 0.3rem;
				color: #000000;
			}

			.itemNameAct {
				font-weight: 600;
				font-size: 0.35rem;
			}

			.itemNameAct::before {
				content: "";
				position: absolute;
				width: 0.4rem;
				height: 0.04rem;
				border-radius: 0.4rem;
				bottom: -0.15rem;
				left: 50%;
				margin-left: -0.2rem;
				background: #000000;
			}
		}

		.tabFixed {
			position: fixed;
			z-index: 999;
			left: 0;
			top: 0;
			width: 100vw;
			padding: 0 0.4rem;
			background: #fff;
		}

		.cqList {
			
			.shadow-box-cq {
				width: 100%;
				// height: 8.28rem;
				filter: drop-shadow(0 0 12px rgba(7, 22, 131, 0.4));
				min-height: 56vh;
					display: flex;
					flex-direction: column;
					justify-content: space-between;
			}
			.item {
				position: relative;
				overflow: hidden;
				width: 100%;
				height: 8.28rem;
				margin-bottom: 0.4rem;
				// background: #000;
				background: #ffffff;
				border-radius: 0.6rem 0.16rem;
				clip-path: polygon(0 5.2%, 6.2% 0, 100% 0, 100% 95%, 94% 100%, 0 100%);
			}


			.label {
				position: absolute;
				z-index: 3;
				right: 0.24rem;
				top: 0.24rem;
				border-radius: 0.08rem;
				height: 0.44rem;
				display: flex;
				align-items: center;
				padding: 0 0.16rem;
				font-size: 0.26rem;
				background: #f2f4f9;
				color: #898da7;
			}

			.labelr {
				margin-right: 0.08rem;
			}

			.labels {
				background: rgba(7, 22, 131, 0.5) !important;
				color: #ffffff;
			}

			.soldout-img {
				position: absolute;
				z-index: 3;
				right: 0.24rem;
				top: 0.24rem;
				width: 1.1rem;
			}

			.artworkImages {
				width: 100%;
				height: 6.7rem;
				// border-top-left-radius: 10%;
				object-fit: cover;
			}

			.hotImg {
				position: absolute;
				left: 0;
				top: 0;
				z-index: 1;
				width: 100%;
				height: 100%;
				background: url("https://file-adwx.oss-cn-hangzhou.aliyuncs.com/newnewImg/images/index/模块_推荐藏品外框.png") no-repeat;
				background-size: 100% 100%;
			}

			.main {
				position: absolute;
				left: 0;
				bottom: 0;
				z-index: 2;
				width: 100%;
				height: calc(100% - 6.7rem);
				padding: 0.24rem 0.4rem 0 0.4rem;

				.mains {
					display: flex;
					align-items: center;
					justify-content: space-between;
					margin-bottom: 0.08rem;

					.name {
						width: 4.22rem;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
						font-size: 0.3rem;
						line-height: 0.45rem;
						font-weight: 600;
					}

					.priceAndScore {
						width: 1.68rem;
						text-align: right;
						line-height: 0.4rem;
						color: #071683;
						.word-ellipsis;
					}

					.price1 {
						font-size: 0.34rem;
						font-weight: 600;
						color: #071683;
					}

					.price2 {
						font-size: 0.24rem;
						font-weight: 700;
						color: #071683;
					}

					.price3 {
						font-size: 0.26rem;
						margin-left: 0.08rem;
						color: #071683;
					}

					.author {
						display: inline-flex;
						align-items: center;
						font-size: 0.26rem;

						.authorHead {
							width: 0.4rem;
							height: 0.4rem;
							border-radius: 50%;
							margin-right: 0.16rem;
							overflow: hidden;
						}

						.authorName {
							width: 3rem;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
							color: #898da7;
						}
					}

					.num {
						font-size: 0.26rem;

						span {
							font-size: 0.28rem;
							font-weight: 700;
							margin-left: 0.08rem;
						}
					}
				}
			}

			.blueItem {
				border-radius: 0.4rem 0.16rem 0.55rem;
				clip-path: polygon(0 3.8%, 4.8% 0, 100% 0, 100% 95.2%, 94% 100%, 0 100%);
				// background: url("https://file-adwx.oss-cn-hangzhou.aliyuncs.com/newnewImg/images/index/推荐藏品外框.png") no-repeat;
				// background-size: 100% 100%;
				.bg-img {
					width: 100%;
					height: 8.28rem;
					position: absolute;
					top: 0;
					left: 0;
					bottom: 0;
					right: 0;
					z-index: 3;
				}

				.main {
					z-index: 6;

					.mains {
						.name {
							color: #FFF;
						}

						.priceAndScore {
							color: #FFF;
						}

						.price1 {
							color: #FFF;
						}

						.price2 {
							color: #FFF;
						}

						.price3 {
							color: #FFF;
						}

						.author {
							.authorName {
								color: #fff;
							}
						}

						.num {
							font-size: 0.26rem;
							color: #FFF;

							span {
								color: #fff;
							}
						}
					}
				}
			}
		}

		.saleTime {
			min-height: 56vh;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			.item {
				margin-bottom: 0.08rem;

				.title {
					background: linear-gradient(180deg,
							#e4e7f2 0%,
							rgba(228, 231, 242, 0) 100%);
					display: flex;
					align-items: center;
					width: 7.1rem;
					margin-left: -0.4rem;
					height: 1.12rem;
					padding-left: 0.4rem;
					padding-bottom: 0.08rem;
					border-radius: 0 0.16rem 0 0;

					.month {
						color: #071683;
						font-weight: 600;
						font-size: 0.48rem;
					}

					.monthIcon {
						width: 0.4rem;
						height: 0.4rem;
						background: url("https://file-adwx.oss-cn-hangzhou.aliyuncs.com/newnewImg/images/index/calendar2.png") no-repeat;
						background-size: 100% 100%;
						margin-left: 0.08rem;
					}
				}

				.itemList {
					.itemListTitle {
						color: #071683;
						font-weight: 600;
						font-size: 0.34rem;
						margin-bottom: 0.29rem;
					}

					.itemListSonMain {
						position: relative;
						
						.img-box{
											width: 1.6rem;
											height: 1.6rem;
											filter: drop-shadow(0 0 12px rgba(7, 22, 131, 0.4));
											position: absolute;
											z-index: 1;
											border-radius: 0.28rem 0.08rem;
											.img {
												position: absolute;
												z-index: 1;
												width: 1.9rem;
												height: 1.9rem;
												border-radius: 0.28rem 0.08rem;
												clip-path: polygon(0 10%,
														10% 0,
														100% 0,
														100% 90%,
														90% 100%,
														0 100%);
												overflow: hidden;
												left: 0.16rem;
												top: -0.16rem;
											
												.artworkImages {
													object-fit: cover;
													width: 100%;
													height: 100%;
												}
											}
										}

						

						.itemListSon {
							width: 100%;
							height: 1.93rem;
							background: #ffffff;
							// background: pink;
							box-shadow: 0px 0.04rem 0.8rem rgba(7, 22, 131, 0.06);
							clip-path: polygon(0 15.5%,
									4.5% 0,
									100% 0,
									100% 84.5%,
									95.5% 100%,
									0 100%);
							border-radius: 0.4rem 0.16rem;
							display: inline-flex;
							margin-bottom: 0.32rem;
							padding-left: 2.3rem;

							.content {
								flex: 1;
								padding-top: 0.24rem;
								padding-right: 0.24rem;

								.name {
									width: 4rem;
									overflow: hidden;
									text-overflow: ellipsis;
									white-space: nowrap;
									font-size: 0.3rem;
									line-height: 0.45rem;
									font-weight: 600;
								}

								.num {
									font-size: 0.26rem;
									margin-bottom: 0.08rem;
									margin-top: 0.16rem;

									span {
										font-size: 0.28rem;
										font-weight: 700;
										margin-left: 0.08rem;
									}
								}

								.priceAndScore {
									line-height: 0.4rem;
									color: #071683;
								}

								.price1 {
									font-size: 0.34rem;
									font-weight: 600;
									color: #071683;
								}

								.price2 {
									font-size: 0.24rem;
									font-weight: 700;
									color: #071683;
								}

								.price3 {
									font-size: 0.26rem;
									margin-left: 0.08rem;
									color: #071683;
								}
							}
						}
					}
				}
			}
		}

.theme-shadow{
	width: 100%;
	// min-height: 45vh;
	// height: 100%;
	filter: drop-shadow(0 0 12px rgba(7, 22, 131, 0.4));
	min-height: 56vh;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
		.theme {
			position: relative;
			overflow: hidden;
			width: 100%;
			height: 6.7rem;
			margin-bottom: 0.4rem;
			background: #ffffff;
			border-radius: 7% 0.16rem;
			clip-path: polygon(0 4.8%, 4.8% 0, 100% 0, 100% 95.2%, 95.2% 100%, 0 100%);
			// background: linear-gradient(180deg, rgba(0, 36, 149, 0) 0%, rgba(0, 36, 149, 0.5) 100%);
			background: linear-gradient(180deg, rgba(0, 36, 149, 0) 0%, #002495 80.73%);

			.artworkImages {
				
				width: 100%;
				height: 100%;
			}

			.theme-bg {
				width: 100%;
				height: 3.7rem;
				position: absolute;
				left: 0;
				bottom: 0;
			}
			.theme-bg-line{
				width: 100%;
				height: 1.07rem;
				position: absolute;
				left: 0;
				bottom: 0;
			}

			.content {
				width: 100%;
				position: absolute;
				left: 0;
				bottom: 0;
				padding: 0.4rem;

				.title {
					width: 100%;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					color: #fff;
					font-weight: 600;
					font-size: 0.56rem;
				}
			}
		}

    .shadow-box {
    	width: 100%;
    	height: 3.22rem;
			filter: drop-shadow(0 0 12px rgba(7, 22, 131, 0.4));
    }
		.authors {
			position: relative;
			overflow: hidden;
			width: 100%;
			height: 3.22rem;
			// box-shadow: 0px 0.04rem 0.8rem rgba(7, 22, 131, 0.16);
			margin-bottom: 0.4rem;
			background: transparent;
			border-radius: 0.5rem 0.2rem;
			clip-path: polygon(0 12%, 5% 0, 100% 0, 100% 88%, 95% 100%, 0 100%);

			// clip-path: polygon(0 12.4%, 4.8% 0, 100% 0, 100% 87.6%, 95.2% 100%, 0 100%);
			.artworkImages {
				width: 100%;
				height: 3.22rem;
				object-fit: cover;
				// clip-path: polygon(0 12.4%, 4.8% 0, 100% 0, 100% 87.6%, 95.2% 100%, 0 100%)
			}

			

			.content {
				width: 100%;
				height: 100%;
				z-index: 1;
				position: absolute;
				left: 0;
				bottom: 0;
				padding: 0.4rem;
				background: linear-gradient(180deg,
						rgba(255, 255, 255, 0.5) 0%,
						#ffffff 93.19%);
				display: inline-flex;
				align-items: flex-end;
				clip-path: polygon(0 12.4%, 4.8% 0, 100% 0, 100% 87.6%, 95.2% 100%, 0 100%);
				.img-box{
					width: 1.6rem;
					height: 1.6rem;
					filter: drop-shadow(0 0 12px rgba(7, 22, 131, 0.4));
					margin-right: 0.2rem;
					.img {
						width: 100%;
						height: 100%;
						// border-radius: 0.3rem 0.08rem;
						clip-path: polygon(0 15%, 15% 0, 100% 0, 100% 85%, 85% 100%, 0 100%);
						margin-right: 0.3rem;
						
						.headUrl {
							border-radius: 0.34rem 0.08rem 0.37rem;
							width: 100%;
							height: 100%;
							object-fit: cover;
						}
					}
				}

				

				.info {
					width: 4rem;

					.name {
						width: 100%;
						font-weight: 600;
						font-size: 0.4rem;
						line-height: 0.56rem;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}

					.depict {
						width: 100%;
						font-weight: 400;
						font-size: 0.3rem;
						line-height: 0.45rem;
						color: #898da7;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}
				}
			}
		}

		.footer {
			width: 100%;
			display: flex;
			justify-content: center;
			margin-top: 0.7rem;

			.footerImg {
				width: 3.42rem;
				height: 0.4rem;
				background: url("https://file-adwx.oss-cn-hangzhou.aliyuncs.com/newnewImg/images/index/内容_logo_40h_浅灰_首页尾部.png") no-repeat;
				background-size: 100% 100%;
			}
		}
	}
</style>
