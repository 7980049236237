<template>
	<div id="app" :class="{'scroll':isscroll}">
		<router-view :key="key"></router-view>
	</div>
</template>

<script>
	import {
		isWxEnv,
		initConfig,
		possessorConfig
	} from './assets/js/utils'
	import wxInit from "./assets/js/wx";
	import {
		initHmJs
	} from './assets/js/hm'
	import api from './assets/http/api'
	export default {
		data() {
			return {
				isscroll: false

			}
		},
		watch: {
			'$route.name'(name) {
				this.setWxConfig();
				if (!localStorage.getItem('possessorConfig')) {
					this.possessorConfigGet();
				}
			},
			'$route'(to, from) {
				// 对路由变化作出响应...
				// console.log('跳转后路由', to.path) //跳转后路由
				if(to.path == '/'){
					this.isscroll = false
					window.location.reload()
				}else{
					this.isscroll = true
				}
				// if(to.path != '/collectionDetail' && to.path != '/collection/detail'){
				// 	// console.log("to.path",to.path)
				// 	localStorage.setItem('shareInfo',null)
				// 	wxInit();
				// }
				
			}
		},
		computed: {
		    key() {
		      return this.$route.path + Math.random();
		    }
		  },

		mounted() {
			initHmJs()
			this.setWxConfig();
			this.possessorConfigGet();
			// wxInit();
			// console.log("kkkk",this.$route.name)

		},
		methods: {

			async possessorConfigGet() {
				let res = await possessorConfig();
				let applyName = res?.applyName
				document.title = applyName || ''
				let node = document.querySelector('#page-icon')
				if (node) {
					node.href = res.shopIco
				}
				this.$store.commit('USER_POSSESS', res)

				localStorage.setItem('possessorConfig', JSON.stringify(res))
				
				if (res.possessor) {
					localStorage.setItem('_pn', res.possessor)
				}
			},
			async setWxConfig() {
				if (isWxEnv()) {
					if (this.$route.name != 'collectionDetail' && route.name != 'collection/detail'){
						this.$nextTick(async () => {
							const possessorConfigData = await possessorConfig() || {}
							let host = window.location.href.split('#')[0]
							let hash = '#/'
							let url = host + hash
							let imgUrl = possessorConfigData?.appletShareUrl || possessorConfigData.logo
							let title = possessorConfigData?.appletShare || possessorConfigData.applyName
							let desc = possessorConfigData?.appletShareRmark
							const path = window.location.href
							const res = await api.getWeixinJsInfo({
								path: path
							})
							if (res.code == 200) {
								const data = res.data || {}
								initConfig(data, {
									link: url,
									imgUrl,
									title,
									desc
								})
							}
						})
					}
					
				}
			}
		}
	}
</script>

<style lang="less">
	// 重置样式
	@import './assets/css/reset.css';

	html,
	body,
	#app {
		width: 100%;
		height: 100%;
		// background: linear-gradient(#00157a 10%, #00072b 50%, #00157a 90%) !important;
		// overflow-x: hidden;
		// overflow-y: auto;
		// -webkit-overflow-scrolling: touch;
		background: #f2f4f9;
	}

	.scroll {
		overflow-x: hidden;
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;
	}
</style>
